import { Container, IconButton, Typography, Unstable_Grid2 } from "@mui/material";
import { Form, Formik } from "formik";
import StepNavigation from "./StepNavigation";
import CancelIcon from "@mui/icons-material/Cancel";
import { FormContext } from "../SubmarineFileForm";
import { useContext } from "react";

type StepWrapperProps = {
  title: string;
  subtitle?: string;
  formikData: {
    initialValues: any;
    onSubmit: (values: any) => void;
    validationSchema: any;
  };
  children: JSX.Element;
};
const StepWrapper = ({ title, subtitle, formikData, children }: StepWrapperProps) => {
  const { handleClose } = useContext(FormContext);
  return (
    <Formik
      initialValues={formikData.initialValues}
      onSubmit={formikData.onSubmit}
      enableReinitialize
      validationSchema={formikData.validationSchema}
    >
      {({ resetForm, values, errors }) => (
        <Form>
          <Container
            disableGutters
            maxWidth="md"
            sx={{
              marginBottom: "5rem",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: "1em",
                right: "1em",
                zIndex: 100,
              }}
              onClick={() => {
                resetForm();
                handleClose();
              }}
            >
              <CancelIcon />
            </IconButton>
            <Unstable_Grid2
              container
              sx={{
                padding: "7rem 1rem 1rem 1rem",
                position: "relative",
                gap: "1rem",
                minHeight: "calc(100vh - 5rem)", // 5rem is the height of the navigation footer
              }}
              direction="column"
            >
              <Unstable_Grid2>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                {title && <Typography variant="h3"> {title}</Typography>}
                {subtitle && (
                  <Typography variant="body1" sx={{ opacity: 0.5, paddingTop: "0.25rem" }}>
                    {subtitle}
                  </Typography>
                )}
              </Unstable_Grid2>
              {children}
            </Unstable_Grid2>
          </Container>

          <StepNavigation />
        </Form>
      )}
    </Formik>
  );
};

export default StepWrapper;
