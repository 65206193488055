import { Box, Button, Unstable_Grid2 } from "@mui/material";
import { useContext } from "react";
import { FormContext } from "../SubmarineFileForm";

const StepNavigation = () => {
  const { activeStepIndex, setActiveStepIndex, nextStepDisabled } = useContext(FormContext);
  const handlePrev = () => {
    setActiveStepIndex(activeStepIndex - 1);
  };
  const leftPanelWidth = "20rem";

  return (
    <Unstable_Grid2
      container
      sx={{
        position: "fixed",
        bottom: 0,
        width: {
          xs: "100%",
          sm: `calc(100% - ${leftPanelWidth})`,
        },
        background: "#F0F0F0",
        paddingBottom: "1rem",
        paddingTop: "1rem",
        border: "1px solid #E0E0E0",
        zIndex: 100,
      }}
      justifyContent="space-around"
      alignItems="center"
    >
      <Box
        sx={{
          position: "absolute",
          top: "calc(-2rem - 1px)",
          background: "white",
          height: "2rem",
          width: "100%",
          WebkitMaskImage:
            "-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,1)))",
        }}
      />
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between", margin: "0 2rem" }}
      >
        {activeStepIndex > 0 && (
          <Button disabled={activeStepIndex === 0} variant="outlined" onClick={handlePrev}>
            Previous Step
          </Button>
        )}

        <Button
          sx={{ marginLeft: "auto" }}
          variant="contained"
          type="submit"
          disabled={nextStepDisabled}
        >
          {activeStepIndex === 3 ? "Submit" : activeStepIndex === 0 ? "Continue" : "Next Step"}
        </Button>
      </Box>
    </Unstable_Grid2>
  );
};

export default StepNavigation;
