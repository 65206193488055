const Folder = () => {
  return (
    <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 5.42857V22.5714C2 24.465 3.46942 26 5.28205 26H28.2564C30.069 26 31.5385 24.465 31.5385 22.5714V8.85714C31.5385 6.9636 30.069 5.42857 28.2564 5.42857H18.4103L15.1282 2H5.28205C3.46942 2 2 3.53502 2 5.42857Z"
        stroke="url(#paint0_linear_2026_7689)"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2026_7689"
          x1="22.2323"
          y1="28.0532"
          x2="7.77804"
          y2="-3.51049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2539D0" />
          <stop offset="1" stopColor="#DEA986" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Folder;
