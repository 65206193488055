import { Menu } from "@mui/material";

import { useState } from "react";
import TableButton from "./TableButton";

type MenuButtonWrapper = {
  label: string;
  icon: JSX.Element;
  children: JSX.Element;
};

export const MenuButtonWrapper = ({ label, icon, children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableButton label={label} icon={icon} onClick={handleClick} />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        MenuListProps={{
          onMouseLeave: handleClose,
          style: { pointerEvents: "auto" },
        }}
        sx={{
          width: "14rem",
          "& .MuiMenu-paper": {
            borderRadius: "13px",
            paddingBottom: 0,
            paddingTop: 0,
          },
          "& .MuiMenu-paper .MuiList-padding": {
            padding: 0,
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default MenuButtonWrapper;
