const Locked = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <g clip-path="url(#clip0_455_12375)">
        <path
          d="M6.70376 28.0909C6.18524 28.0909 5.74136 27.901 5.37211 27.5212C5.00286 27.1414 4.81824 26.6848 4.81824 26.1515V12.1232C4.81824 11.5899 5.00286 11.1333 5.37211 10.7535C5.74136 10.3737 6.18524 10.1838 6.70376 10.1838H8.90353V7.08081C8.90353 5.38168 9.48601 3.93333 10.651 2.73576C11.8159 1.53818 13.2248 0.939392 14.8777 0.939392C16.5305 0.939392 17.9383 1.53818 19.1011 2.73576C20.2638 3.93333 20.8452 5.38168 20.8452 7.08081V10.1838H23.0449C23.5635 10.1838 24.0074 10.3737 24.3766 10.7535C24.7458 11.1333 24.9305 11.5899 24.9305 12.1232V26.1515C24.9305 26.6848 24.7458 27.1414 24.3766 27.5212C24.0074 27.901 23.5635 28.0909 23.0449 28.0909H6.70376ZM6.70376 26.1515H23.0449V12.1232H6.70376V26.1515ZM14.8796 21.6263C15.5465 21.6263 16.1157 21.3889 16.587 20.9141C17.0584 20.4394 17.2941 19.8687 17.2941 19.202C17.2941 18.5556 17.0567 17.9683 16.5818 17.4404C16.1069 16.9125 15.536 16.6485 14.8691 16.6485C14.2022 16.6485 13.6331 16.9125 13.1617 17.4404C12.6903 17.9683 12.4546 18.5609 12.4546 19.2182C12.4546 19.8754 12.6921 20.4411 13.167 20.9151C13.6419 21.3892 14.2127 21.6263 14.8796 21.6263ZM10.7891 10.1838H18.9597V7.08081C18.9597 5.91357 18.5628 4.92143 17.7692 4.10439C16.9755 3.28732 16.0118 2.87879 14.8781 2.87879C13.7443 2.87879 12.7793 3.28732 11.9832 4.10439C11.1871 4.92143 10.7891 5.91357 10.7891 7.08081V10.1838Z"
          fill="url(#paint0_linear_455_12375)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_455_12375"
          x1="14.8744"
          y1="0.939392"
          x2="14.8744"
          y2="28.0909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A1889E" />
          <stop offset="1" stop-color="#3A41C6" />
        </linearGradient>
        <clipPath id="clip0_455_12375">
          <rect
            width="28.1212"
            height="28.1212"
            fill="white"
            transform="translate(0.939453 0.939392)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Locked;
