const NoLockIcon = () => {
  return (
    <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.10413 9.52707H14.6979V6.42707C14.6979 5.26098 14.2901 4.26981 13.4746 3.45356C12.6591 2.63729 11.6688 2.22916 10.5038 2.22916C9.33873 2.22916 8.34718 2.63729 7.52913 3.45356C6.71107 4.26981 6.30204 5.26098 6.30204 6.42707H4.36454C4.36454 4.72638 4.96308 3.27864 6.16015 2.08384C7.35723 0.889052 8.80497 0.291656 10.5034 0.291656C12.2018 0.291656 13.6484 0.88986 14.8432 2.08627C16.038 3.28267 16.6354 4.72961 16.6354 6.42707V9.52707H18.8958C19.4286 9.52707 19.8847 9.71679 20.2642 10.0962C20.6436 10.4756 20.8333 10.9318 20.8333 11.4646V25.4792C20.8333 26.012 20.6436 26.4681 20.2642 26.8475C19.8847 27.2269 19.4286 27.4167 18.8958 27.4167H2.10413C1.57131 27.4167 1.11519 27.2269 0.735767 26.8475C0.35634 26.4681 0.166626 26.012 0.166626 25.4792V11.4646C0.166626 10.9318 0.35634 10.4756 0.735767 10.0962C1.11519 9.71679 1.57131 9.52707 2.10413 9.52707ZM2.10413 25.4792H18.8958V11.4646H2.10413V25.4792ZM10.5054 20.9583C11.1907 20.9583 11.7755 20.7212 12.2599 20.2469C12.7442 19.7726 12.9864 19.2025 12.9864 18.5364C12.9864 17.8906 12.7424 17.304 12.2544 16.7766C11.7664 16.2491 11.1798 15.9854 10.4945 15.9854C9.80926 15.9854 9.22444 16.2491 8.74006 16.7766C8.25569 17.304 8.0135 17.896 8.0135 18.5526C8.0135 19.2092 8.2575 19.7743 8.74549 20.2479C9.23348 20.7215 9.82011 20.9583 10.5054 20.9583Z"
        fill="#997FA4"
      />
    </svg>
  );
};
export default NoLockIcon;
