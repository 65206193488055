import {
  Table,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  Unstable_Grid2,
  TableCell,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LinkableWithOriginalNameAndOrder } from "./LinkableStepper";
import AddIcon from "@mui/icons-material/Add";
import Cross from "@mui/icons-material/Close";
import withAddCTA, { IWithAddCTA } from "./WithAddCTA";
import { getIconByExtension } from "./ListCID";

const AddCTADesktop = ({
  handleBlur,
  handleUrlChange,
  handleLabelChange,
  handleClear,
  labels,
  urls,
  orderedLinkables,
}: IWithAddCTA) => {
  return (
    <Table
      sx={{
        marginTop: "1rem",
        width: "100%",
        bgcolor: "background.paper",
      }}
    >
      <TableHead>
        <TableRow
          sx={{
            paddingTop: 0,
          }}
        >
          <TableCell
            sx={{
              paddingLeft: "0",
              width: "14rem",
            }}
          >
            Name
          </TableCell>
          <TableCell
            sx={{
              width: "14rem",
            }}
          >
            Link title
          </TableCell>
          <TableCell>Link url</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderedLinkables.map((value) => {
          return (
            <CtaForm
              key={value.submarine_cid}
              value={value}
              labels={labels}
              urls={urls}
              handleLabelChange={handleLabelChange}
              handleBlur={handleBlur}
              handleClear={handleClear}
              handleUrlChange={handleUrlChange}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
export default withAddCTA(AddCTADesktop);

function truncate(str: string, n: number) {
  if (!str) return "";
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

const CtaForm = ({
  value,
  labels,
  handleLabelChange,
  handleBlur,
  handleUrlChange,
  handleClear,
  urls,
}) => {
  const [displayCTAForm, setDisplay] = useState(!!labels[value.submarine_cid]);
  useEffect(() => {
    setDisplay(!!labels[value.submarine_cid]);
  }, [labels[value.submarine_cid]]);
  return (
    <TableRow>
      <TableCell
        sx={{
          paddingLeft: "0",
          maxWidth: "12rem",
        }}
      >
        <Unstable_Grid2
          container
          wrap="nowrap"
          gap="1rem"
          justifyContent={"left"}
          alignItems={"center"}
        >
          {getIconByExtension(value.originalname)}
          <Typography
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
            }}
          >
            {truncate(value.originalname, 20)}
          </Typography>
        </Unstable_Grid2>
      </TableCell>

      {displayCTAForm && (
        <TableCell>
          <TextField
            value={labels[value.submarine_cid]}
            name={value.submarine_cid}
            onChange={handleLabelChange}
            onBlur={handleBlur}
          />
        </TableCell>
      )}

      {displayCTAForm && (
        <TableCell>
          <TextField
            placeholder="https://example.com"
            value={urls[value.submarine_cid]}
            name={value.submarine_cid}
            onChange={handleUrlChange}
            onBlur={handleBlur}
          />
        </TableCell>
      )}
      {!displayCTAForm && (
        <TableCell
          colSpan={3}
          sx={{
            textAlign: "right",
          }}
        >
          <IconButton
            sx={{
              color: "primary.main",
              borderRadius: "0.5rem",
            }}
            onClick={() => {
              setDisplay(true);
            }}
          >
            <AddIcon />
            <Typography
              sx={{
                color: "primary.main",
              }}
            >
              Add Link
            </Typography>
          </IconButton>
        </TableCell>
      )}
      {displayCTAForm && (
        <TableCell
          colSpan={1}
          sx={{
            textAlign: "right",
          }}
        >
          <IconButton
            sx={{
              borderRadius: "0.5rem",
            }}
            onClick={() => {
              setDisplay(false);
              handleClear(value.submarine_cid);
            }}
          >
            <Cross />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};
