import { Unstable_Grid2 } from "@mui/material";
import { useContext } from "react";
import TwitchForm from "../../LockTypes/Twitch/TwitchForm";
import * as Yup from "yup";
import StepWrapper from "./StepWrapper";
import EVMContractAddress from "../../LockTypes/NFT/ETH/ContractAddress";
import FlowContractAddress from "../../LockTypes/NFT/Flow/ContractAddress";
import SolanaContractAddress from "../../LockTypes/NFT/Solana/ContractAddress";
import LocationForm from "../../LockTypes/Location/LocationForm";
import { FormContext } from "../SubmarineFileForm";
import NoLockForm from "components/Submarine/LockTypes/NoLockForm";
import { ANALYTICS } from "constants/rudderstack_events";

const UnlockDetails = () => {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(FormContext);

  const selectedMethods = formData.unlockInfo.map((info) => info.type);
  const isNoLock = selectedMethods[0] === "noLock";
  if (isNoLock) {
    setActiveStepIndex(activeStepIndex - 1);
  }
  const validationSchemaPartials = {};
  const initialData = {};
  const unlockForms = selectedMethods.map((method) => {
    switch (method) {
      case "twitch":
        initialData[method] = TwitchForm.initialValues(formData);
        validationSchemaPartials[method] = TwitchForm.validationSchema;
        return <TwitchForm key="twitch " />;
      case "location":
        initialData[method] = LocationForm.initialValues(formData);
        validationSchemaPartials[method] = LocationForm.validationSchema;
        return <LocationForm key="location" />;
      case "evm":
        initialData[method] = EVMContractAddress.initialValues(formData);
        validationSchemaPartials[method] = EVMContractAddress.validationSchema;
        return <EVMContractAddress key="evm" />;
      case "flow":
        initialData[method] = FlowContractAddress.initialValues(formData);
        validationSchemaPartials[method] = FlowContractAddress.validationSchema;
        return <FlowContractAddress key="flow " />;
      case "solana":
        initialData[method] = SolanaContractAddress.initialValues(formData);
        validationSchemaPartials[method] = SolanaContractAddress.validationSchema;
        return <SolanaContractAddress key="solana" />;
      case "noLock":
        initialData[method] = NoLockForm.initialValues(formData);
        validationSchemaPartials[method] = NoLockForm.validationSchema;
        return <NoLockForm key="noLock" />;
    }
  });

  const validationSchema = Yup.object().shape(validationSchemaPartials);

  const getUnlockInfo = (values, formData) => {
    return formData.unlockInfo.map((unlockInfo) => {
      return values[unlockInfo.type];
    });
  };

  const formikData = {
    initialValues: initialData,
    onSubmit: (values) => {
      const unlockInfo = getUnlockInfo(values, formData);
      setFormData({ ...formData, unlockInfo });

      window.rudderanalytics?.track(ANALYTICS.SUBMARINE_CREATION.UNLOCK_CONFIGURED, {
        fileType: formData.type,
        linkableCount: formData?.linkables?.length ? formData.linkables.length : undefined,
        unlockMethod: unlockInfo.map((unlock) => unlock.type).join(";"),
      });
      setActiveStepIndex(activeStepIndex + 1);
    },
    validationSchema,
  };
  return (
    <StepWrapper
      formikData={formikData}
      title="Unlock Settings"
      subtitle="Configure your unlocking preferences"
    >
      <Unstable_Grid2>{unlockForms}</Unstable_Grid2>
    </StepWrapper>
  );
};

export default UnlockDetails;
