import { useContext } from "react";
import FileDetailForm from "./Steps/FileDetailForm";

import LockTypeSelect from "./Steps/LockTypeSelect";
import UnlockDetails from "./Steps/UnlockDetails";
import CustomizationForm from "./Steps/Customization";
import ShareYourProject from "./Steps/ShareYourProject";
import { FormContext } from "./SubmarineFileForm";

const ActiveStep = () => {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <FileDetailForm />;
      break;
    case 1:
      stepContent = <LockTypeSelect />;
      break;
    case 2:
      stepContent = <UnlockDetails />;
      break;
    case 3:
      stepContent = <CustomizationForm />;
      break;
    case 4:
      stepContent = <ShareYourProject />;
  }
  return stepContent;
};

export default ActiveStep;
