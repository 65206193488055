const Location = () => {
  return (
    <svg width="27" height="31" viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4325 21.9359C20.8175 23.5508 17.1728 27.1956 15.2335 29.1348C14.532 29.8364 13.3968 29.8371 12.6952 29.1356C10.7878 27.2281 7.22133 23.6617 5.49554 21.9359C0.818543 17.2589 0.818543 9.67596 5.49554 4.99896C10.1725 0.321961 17.7555 0.321961 22.4325 4.99896C27.1095 9.67596 27.1095 17.2589 22.4325 21.9359Z"
        stroke="url(#paint0_linear_2088_7820)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4551 13.4674C18.4551 15.9478 16.4444 17.9585 13.964 17.9585C11.4836 17.9585 9.47293 15.9478 9.47293 13.4674C9.47293 10.9871 11.4836 8.97634 13.964 8.97634C16.4444 8.97634 18.4551 10.9871 18.4551 13.4674Z"
        stroke="url(#paint1_linear_2088_7820)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2088_7820"
          x1="16.7031"
          y1="24.7733"
          x2="-1.79257"
          y2="-2.52228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2539D0" />
          <stop offset="1" stopColor="#DEA986" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2088_7820"
          x1="16.7031"
          y1="24.7733"
          x2="-1.79257"
          y2="-2.52228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2539D0" />
          <stop offset="1" stopColor="#DEA986" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Location;
