import { useTheme } from "@emotion/react";
import { CameraAltOutlined, FormatColorFillOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Badge,
  Box,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import Image from "next/image";
import { useState } from "react";
import { ChromePicker } from "react-color";
import { useAppSelector } from "../../../store/hooks";
import { selectGatewayUrl } from "../../../store/selectors/authSelectors";
import { Customizations } from "../../../types/Customizations";
import UploadImagePublic from "./UploadImagePublic";

interface CustomiseLockScreenProps {
  hasNoLock: boolean;
}

function UploadImageCard({ title, gatewayUrl, fieldType, defaultImageSrc }) {
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext<Customizations>();
  const [isUploading, setIsUploading] = useState<Boolean>(false);

  return (
    <Card
      sx={{
        width: "100%",
        px: 2,
        py: 1.5,
        backgroundColor: "#fff",
        boxShadow: "0px 6px 24px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.02)",
      }}
      elevation={0}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flexGrow: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography fontWeight={500} variant="h6">
            {title}
          </Typography>
          <UploadImagePublic
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            setIpfsHash={(hash: string) => setFieldValue(fieldType, hash)}
          >
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
                color: "primary.main",
              }}
            >
              {isUploading ? (
                <Typography color="primary.main" fontWeight={500} variant="subtitle2">
                  Processing...
                </Typography>
              ) : (
                <>
                  <CameraAltOutlined fontSize="inherit" />
                  <Typography color="primary.main" fontWeight={500} variant="subtitle2">
                    Replace
                  </Typography>
                </>
              )}
            </Box>
          </UploadImagePublic>
        </Box>

        <Box
          sx={{
            alignSelf: "flex-end",
            position: "relative",
            width:
              fieldType === "backgroundCid" || fieldType === "socialImage"
                ? theme.spacing(9.75)
                : theme.spacing(6),
            height: theme.spacing(6),
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isUploading ? "#D2D2D2" : "transparent",
            borderRadius: theme.shape.borderRadius / 2,
          }}
        >
          {isUploading ? (
            <CircularProgress size="20px" />
          ) : (
            <Image
              width={48}
              height={48}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: fieldType === "thumbnail" ? "50%" : 0,
              }}
              src={getImageUrl(gatewayUrl, values?.[fieldType], defaultImageSrc)}
              alt={`previrew for ${title}`}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
}

const getImageUrl = (gatewayUrl: string, imageCidOrUrl: string, defaultValue) => {
  if (!imageCidOrUrl) return defaultValue;
  if (imageCidOrUrl.includes("user/")) {
    return `${process.env.NEXT_PUBLIC_R2_URL}/${imageCidOrUrl}`;
  } else {
    return `${gatewayUrl}/ipfs/${imageCidOrUrl}`;
  }
};
function GetColorCard({ title, fieldType }) {
  const { values, setFieldValue, setFieldError, errors } = useFormikContext<Customizations>();
  const theme = useTheme();
  const [anchorBttn, setAnchorBttn] = useState<HTMLElement | null>(null);
  const [inputText, setInputText] = useState<string>(values?.[fieldType]?.hex || "");

  return (
    <Card
      sx={{
        width: "100%",
        px: 2,
        py: 1.5,
        backgroundColor: "#fff",
        boxShadow: "0px 6px 24px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.02)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            flexGrow: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography fontWeight={500} variant="h6">
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, flexGrow: 2 }}>
            <IconButton sx={{ px: 0 }} onClick={(event) => setAnchorBttn(event?.currentTarget)}>
              <FormatColorFillOutlined fontSize="small" color="primary" />
            </IconButton>
            <TextField
              sx={{
                "& > .MuiFormHelperText-root": {
                  margin: 0,
                },
                "& .MuiInputBase-input": {
                  padding: (theme) => theme.spacing(1, 1.5),
                },
              }}
              variant="outlined"
              placeholder="HEX#"
              error={Boolean(errors[fieldType])}
              size="small"
              value={inputText}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^#?([0-9A-F]{3}){1,2}$/i;
                if (value.length > 0 && !regex.test(value)) {
                  setFieldError(fieldType, "Please type correct HEX color");
                } else {
                  setFieldValue(fieldType, {
                    hex: value.includes("#") ? value : `#${value}`,
                  });
                }
                setInputText(value);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "inline-flex",
                width: theme.spacing(3),
                height: theme.spacing(3),
                borderRadius: "50%",
                border: 2,
                borderColor: theme.palette.divider,
                backgroundColor: values[fieldType]?.hex,
                cursor: "pointer",
              }}
              onClick={(event) => setAnchorBttn(event?.currentTarget)}
            />
          </Box>
          <Popover
            id={fieldType}
            open={Boolean(anchorBttn)}
            anchorEl={anchorBttn}
            onClose={() => setAnchorBttn(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPopover-paper": {
                boxShadow:
                  "0px 6px 24px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.02)",
              },
            }}
          >
            <ChromePicker
              color={inputText}
              onChangeComplete={(color) => {
                setFieldValue(fieldType, { hex: color?.hex });
                setInputText(color?.hex);
              }}
            />
          </Popover>
        </Box>
      </Box>
    </Card>
  );
}

const CustomizeLockScreen = ({ hasNoLock }: CustomiseLockScreenProps) => {
  const gatewayUrl = useAppSelector(selectGatewayUrl);

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "10rem",
        flexDirection: "column",
        gap: (theme) => theme.spacing(4),
        maxWidth: "420px",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, minmax(200px, 1fr))",
            lg: "repeat(auto-fill, minmax(200px, 1fr))",
          },
          gap: (theme) => theme.spacing(2),
        }}
      >
        <UploadImageCard
          title="Logo"
          fieldType="logoCid"
          gatewayUrl={gatewayUrl}
          defaultImageSrc="/zigzag_placeholder.png"
        />
        {!hasNoLock && (
          <UploadImageCard
            title="Thumbnail"
            fieldType="thumbnail"
            gatewayUrl={gatewayUrl}
            defaultImageSrc="/placeholder_thumbnail.png"
          />
        )}
        <UploadImageCard
          title="Background"
          fieldType="backgroundCid"
          gatewayUrl={gatewayUrl}
          defaultImageSrc="/purple_placeholder.png"
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(2) }}>
        <Typography variant="h6">Button</Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fill, minmax(200px, 1fr))",
              lg: "repeat(2, minmax(200px, 1fr))",
            },
            gap: (theme) => theme.spacing(2),
          }}
        >
          <GetColorCard title="Color" fieldType="buttonColor" />
          <GetColorCard title="Text" fieldType="buttonTextColor" />
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(2) }}>
        <Typography variant="h6">Social</Typography>
        <Box sx={{ display: "flex", justifyContent: "stretch" }}>
          <UploadImageCard
            title="Linkables Preview"
            fieldType="socialImage"
            gatewayUrl={gatewayUrl}
            defaultImageSrc="/default_rich_preview.jpeg"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CustomizeLockScreen;
