const Public = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M16 29.3333C14.1555 29.3333 12.4222 28.9833 10.8 28.2833C9.17774 27.5833 7.76663 26.6333 6.56663 25.4333C5.36663 24.2333 4.41663 22.8222 3.71663 21.2C3.01663 19.5778 2.66663 17.8444 2.66663 16C2.66663 14.1555 3.01663 12.4222 3.71663 10.8C4.41663 9.17777 5.36663 7.76666 6.56663 6.56666C7.76663 5.36666 9.17774 4.41666 10.8 3.71666C12.4222 3.01666 14.1555 2.66666 16 2.66666C17.8444 2.66666 19.5777 3.01666 21.2 3.71666C22.8222 4.41666 24.2333 5.36666 25.4333 6.56666C26.6333 7.76666 27.5833 9.17777 28.2833 10.8C28.9833 12.4222 29.3333 14.1555 29.3333 16C29.3333 17.8444 28.9833 19.5778 28.2833 21.2C27.5833 22.8222 26.6333 24.2333 25.4333 25.4333C24.2333 26.6333 22.8222 27.5833 21.2 28.2833C19.5777 28.9833 17.8444 29.3333 16 29.3333ZM14.5666 27.3V24.5667C13.7888 24.5667 13.1333 24.2778 12.6 23.7C12.0666 23.1222 11.8 22.4444 11.8 21.6667V20.2L4.96663 13.3667C4.85551 13.8111 4.77774 14.25 4.73329 14.6833C4.68885 15.1167 4.66663 15.5555 4.66663 16C4.66663 18.8889 5.60551 21.4111 7.48329 23.5667C9.36107 25.7222 11.7222 26.9667 14.5666 27.3ZM24.3666 23.7C24.8555 23.1667 25.2833 22.6 25.65 22C26.0166 21.4 26.3277 20.7722 26.5833 20.1167C26.8388 19.4611 27.0277 18.7889 27.15 18.1C27.2722 17.4111 27.3333 16.7111 27.3333 16C27.3333 13.6444 26.6888 11.5055 25.4 9.58332C24.1111 7.6611 22.3888 6.25555 20.2333 5.36666V5.96666C20.2333 6.74443 19.9666 7.42221 19.4333 7.99999C18.9 8.57777 18.2444 8.86666 17.4666 8.86666H14.5666V11.7667C14.5666 12.1444 14.4166 12.4555 14.1166 12.7C13.8166 12.9444 13.4777 13.0667 13.1 13.0667H10.3333V16H18.9333C19.3111 16 19.6222 16.1444 19.8666 16.4333C20.1111 16.7222 20.2333 17.0555 20.2333 17.4333V21.6667H21.6666C22.3111 21.6667 22.8777 21.8555 23.3666 22.2333C23.8555 22.6111 24.1888 23.1 24.3666 23.7Z"
        fill="url(#paint0_linear_487_784)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_487_784"
          x1="5.49995"
          y1="5"
          x2="29"
          y2="27.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.0001" stop-color="#A1889E" stop-opacity="0.984375" />
          <stop offset="0.998157" stop-color="#3A41C6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Public;
