import FormikTextfield from "../../../../Form/FormikTextfield";

import { useContext, useEffect } from "react";
import * as Yup from "yup";
import { FormContext } from "../../SubmarineFileForm";
import StepWrapper from "../StepWrapper";
import { FileType } from "@customTypes/ProjectInfo";
import FileTypeTab from "./FileTypeTab";
import FileSelector from "./FileSelector";
import { ANALYTICS } from "constants/rudderstack_events";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").max(100, "Too Long!").required("Required"),
  description: Yup.string().required("Required").min(3, "Too Short!").max(100, "Too Long!"),
  linkables: Yup.array().when("type", {
    is: FileType.Linkable,
    then: Yup.array().min(2, "Must select at least two pieces of content"),
  }),
  submarineCID: Yup.string().when("type", {
    is: (type) => type === FileType.File || type === FileType.Folder,
    then: Yup.string().required("Required"),
  }),
});
const FileDetailForm = () => {
  const { activeStepIndex, setActiveStepIndex, setFormData, formData } = useContext(FormContext);

  const formikData = {
    initialValues: {
      name: formData.name,
      description: formData.description,
      submarineCID: formData.submarineCID,
      type: formData.type || FileType.Linkable,
      linkables: formData.linkables || [],
    },
    validationSchema,
    onSubmit: (values) => {
      const capitalizedName = capitalizeString(values.name);
      const capitalizedDescription = capitalizeString(values.description);
      values.name = capitalizedName;
      values.description = capitalizedDescription;
      const data = { ...formData, ...values };
      setFormData(data);
      window.rudderanalytics?.track(ANALYTICS.SUBMARINE_CREATION.CONTENT_ADDED, {
        fileType: data.type,
        linkableCount: data?.linkables?.length ? data.linkables.length : undefined,
      });

      setActiveStepIndex(activeStepIndex + 1);
    },
  };
  return (
    <StepWrapper formikData={formikData} title="Content Details">
      <>
        <FormikTextfield
          type="text"
          name="name"
          label="Name"
          maxLength={100}
          inputprops={{ style: { textTransform: "capitalize" } }}
        />
        <FormikTextfield type="text" name="description" label="Description" maxLength={400} />
        <FileTypeTab />
        <FileSelector />
      </>
    </StepWrapper>
  );
};

export default FileDetailForm;

const capitalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
