import { forwardRef, memo, useEffect } from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import SubmarineFileForm from "./SubmarineFileForm";
import { useIntercom } from "react-use-intercom";
import { ANALYTICS } from "constants/rudderstack_events";
interface SubmarineProps {
  shortId: string | undefined;
  open: boolean;
  onClose: () => void;
}
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubmarineFileDialog = ({ open, onClose, shortId }: SubmarineProps) => {
  const { update } = useIntercom();
  useEffect(() => {
    if (open) {
      update({ hideDefaultLauncher: true });
      window.rudderanalytics?.track(ANALYTICS.SUBMARINE_CREATION.START, {});
    }
    return () => update({ hideDefaultLauncher: false });
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          overflow: "auto",
        },
      }}
      TransitionComponent={Transition}
      fullScreen
      fullWidth
    >
      <SubmarineFileForm handleClose={onClose} shortId={shortId} />
    </Dialog>
  );
};

export default memo(SubmarineFileDialog);
