import { useEffect, useState } from "react";
import GoogleMapsCoordsDialog from "./GoogleMapsCoordsDialog";
import { useFormikContext } from "formik";
import InformationCircleIconStyled from "../InformationCircleIconStyled";
import FormikTextfield from "../../../Form/FormikTextfield";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Unstable_Grid2,
} from "@mui/material";
import AddressAutocomplete from "./AddressAutocomplete";
import { ProjectInfo } from "@customTypes/ProjectInfo";
import { UnlockInfoLocation, UnlockTypes } from "../../../../types/UnlockInfo";
import * as Yup from "yup";

enum LocationType {
  Address = "address",
  LatLong = "latlong",
}

const LocationForm: React.FC & { validationSchema: Yup.ObjectSchema<any> } & {
  initialValues: (formData: ProjectInfo) => UnlockInfoLocation;
} = () => {
  const [gettingLocation, setGettingLocation] = useState(false);
  const [googleMapsDialogOpen, setGoogleMapsDialogOpen] = useState(false);
  const { setFieldValue, values } = useFormikContext<ProjectInfo>();
  const unlockInfo = values["location"] as UnlockInfoLocation;

  const [locationType, setLocationType] = useState(
    unlockInfo.type === "location" && unlockInfo.lat ? LocationType.LatLong : LocationType.Address
  );
  const detectLocation = async (e) => {
    e.preventDefault();
    setLocationType(LocationType.LatLong);
    setGettingLocation(true);
    if (!navigator.geolocation) {
      setGettingLocation(false);
      alert("geolocation not supported");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setFieldValue(`location.lat`, latitude);
          setFieldValue(`location.long`, longitude);
          setGettingLocation(false);
        },
        (error) => {
          setGettingLocation(false);
          alert(error);
        }
      );
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setLocationType(event.target.value as LocationType);
  };

  return (
    <Unstable_Grid2 container direction={"column"} sx={{ gap: "1em", marginTop: "2em" }}>
      <Unstable_Grid2
        container
        sx={{ gap: "1em", justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography variant="h5">Location Details</Typography>
        <Button variant="outlined" onClick={detectLocation}>
          {gettingLocation ? "Detecting location..." : "Detect Location"}
        </Button>
      </Unstable_Grid2>
      <RadioGroup row value={locationType} onChange={handleRadioChange}>
        <FormControlLabel value={LocationType.Address} control={<Radio />} label="Address" />
        <FormControlLabel
          value={LocationType.LatLong}
          control={<Radio />}
          label="Latitude / Longitude"
        />
      </RadioGroup>
      {locationType === LocationType.Address ? (
        <AddressAutocomplete />
      ) : (
        <>
          <FormikTextfield
            name={`location.lat`}
            label="Latitude"
            required
            adornment={
              <span aria-label="button" onClick={() => setGoogleMapsDialogOpen(true)}>
                <InformationCircleIconStyled />
              </span>
            }
          />
          <FormikTextfield
            name={`location.long`}
            label="Longitude"
            required
            adornment={
              <span aria-label="button" onClick={() => setGoogleMapsDialogOpen(true)}>
                <InformationCircleIconStyled />
              </span>
            }
          />
        </>
      )}
      <FormikTextfield name={`location.distance`} label="Allowed Range (in miles)" required />
      <GoogleMapsCoordsDialog
        googleMapsDialogOpen={googleMapsDialogOpen}
        setGoogleMapsDialogOpen={setGoogleMapsDialogOpen}
      />
    </Unstable_Grid2>
  );
};

export default LocationForm;

LocationForm.validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  lat: Yup.number().required("Required").notOneOf([0], "Required"),
  long: Yup.number().required("Required").notOneOf([0], "Required"),
  distance: Yup.string().required("Required"),
  place: Yup.object().optional(),
});

LocationForm.initialValues = (formData: ProjectInfo) => {
  const index = formData.unlockInfo.findIndex((method) => method.type === UnlockTypes.Location);
  const unlockInfo = formData.unlockInfo[index] as UnlockInfoLocation;
  return {
    type: UnlockTypes.Location,
    lat: unlockInfo.lat || 0,
    long: unlockInfo.long || 0,
    distance: unlockInfo.distance || "",
    place: unlockInfo.place || {
      description: "",
      place_id: "",
      structured_formatting: {
        main_text: "",
        secondary_text: "",
        main_text_matched_substrings: [
          {
            offset: 0,
            length: 0,
          },
        ],
      },
    },
  };
};
