import { ProjectInfo } from "@customTypes/ProjectInfo";
import { UnlockInfoNoLock, UnlockTypes } from "@customTypes/UnlockInfo";
import { Typography, Unstable_Grid2 } from "@mui/material";
import React from "react";
import * as Yup from "yup";

const NoLockForm: React.FC & { validationSchema: Yup.ObjectSchema<any> } & {
  initialValues: (formData: ProjectInfo) => UnlockInfoNoLock;
} = () => {
  return (
    <Unstable_Grid2 container direction={"column"} sx={{ gap: "1em", marginTop: "2em" }}>
      <Typography variant="h5">&quot;No Lock&quot; requires no configuration.</Typography>
    </Unstable_Grid2>
  );
};
NoLockForm.validationSchema = Yup.object().shape({
  type: Yup.string().required(),
});

NoLockForm.initialValues = () => {
  return {
    type: UnlockTypes.NoLock,
  };
};

export default NoLockForm;
