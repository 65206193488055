import { useEffect, useState } from "react";
import { LinkableWithOriginalNameAndOrder } from "./LinkableStepper";

interface withAddCTAProps {
  orderedLinkables: LinkableWithOriginalNameAndOrder[];
  setFieldValue: (field: string, value: any) => void;
}
export interface IWithAddCTA {
  handleBlur: () => void;
  handleUrlChange: (e: { target: { name: string; value: string } }) => void;
  handleLabelChange: (e: { target: { name: string; value: string } }) => void;
  handleClear: (submarineCid: string) => void;
  labels: Record<string, string>;
  urls: Record<string, string>;
  orderedLinkables: LinkableWithOriginalNameAndOrder[];
}

const withAddCTA = (Component) => {
  const MyComp = ({ orderedLinkables, setFieldValue }: withAddCTAProps) => {
    const [labels, setLabels] = useState<Record<string, string>>({});
    const [urls, setUrls] = useState<Record<string, string>>({});
    const handleLabelChange = (e: Pick<React.ChangeEvent<HTMLInputElement>, "target">) => {
      const { value, name } = e.target;
      setLabels((prev) => ({ ...prev, [name]: value }));
    };
    const handleUrlChange = (e: Pick<React.ChangeEvent<HTMLInputElement>, "target">) => {
      const { value, name } = e.target;
      setUrls((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
      const newLabels: Record<string, string> = {};
      const newUrls: Record<string, string> = {};
      orderedLinkables.forEach((i) => {
        newLabels[i.submarine_cid] = i?.cta?.label || "";
        newUrls[i.submarine_cid] = i?.cta?.url || "";
      });
      setLabels(newLabels);
      setUrls(newUrls);
    }, []);
    const handleBlur = () => {
      const allCids = orderedLinkables.map((i) => {
        return {
          order: i.order,
          submarine_cid: i.submarine_cid,
          cta: {
            label: labels[i.submarine_cid],
            url: urls[i.submarine_cid],
          },
        };
      });
      setFieldValue("linkables", allCids);
    };

    const handleClear = (submarineCid) => {
      const allCids = orderedLinkables.map((i) => {
        if (i.submarine_cid === submarineCid) {
          return {
            order: i.order,
            submarine_cid: i.submarine_cid,
            cta: {
              label: "",
              url: "",
            },
          };
        }
        return i;
      });
      setFieldValue("linkables", allCids);
    };
    return (
      <Component
        handleBlur={handleBlur}
        handleUrlChange={handleUrlChange}
        handleLabelChange={handleLabelChange}
        handleClear={handleClear}
        labels={labels}
        urls={urls}
        orderedLinkables={orderedLinkables}
      />
    );
  };
  MyComp.displayName = "withAddCTA";
  return MyComp;
};

export default withAddCTA;
