import { Typography, Unstable_Grid2 } from "@mui/material";
import { useState } from "react";
import FormikTextfield from "../../../../Form/FormikTextfield";
import InformationCircleIconStyled from "../../InformationCircleIconStyled";
import MintAddressDialog from "./MintAddressDialog";
import UpdateAuthorityDialog from "./UpdateAuthorityDialog";
import * as Yup from "yup";
import { UnlockInfoSolana, UnlockTypes } from "../../../../../types/UnlockInfo";
import NetworkSelector from "../NetworkSelector";
import { ProjectInfo } from "@customTypes/ProjectInfo";

const SolanaContractAddress: React.FC & { validationSchema: Yup.ObjectSchema<any> } & {
  initialValues: (formData: ProjectInfo) => UnlockInfoSolana;
} = () => {
  const [updateAuthorityDialogOpen, setUpdateAuthorityDialogOpen] = useState(false);
  const [mintAddressDialogOpen, setMintAddressDialogOpen] = useState(false);
  return (
    <Unstable_Grid2 container direction={"column"} sx={{ gap: "1em" }}>
      <Typography variant="h5">Solana NFT Details</Typography>
      <NetworkSelector options={["Mainnet-Beta", "Devnet"]} type={"solana"} />
      <FormikTextfield
        type="text"
        name="solana.mintAddress"
        label="Mint Address"
        required
        adornment={
          <span
            aria-label="button"
            style={{ cursor: "pointer" }}
            onClick={() => setMintAddressDialogOpen(true)}
          >
            <InformationCircleIconStyled />
          </span>
        }
      />
      <FormikTextfield
        type="text"
        name="solana.updateAuthority"
        label="Update Authority"
        required
        adornment={
          <span
            style={{ cursor: "pointer" }}
            aria-label="button"
            onClick={() => setUpdateAuthorityDialogOpen(true)}
          >
            <InformationCircleIconStyled />
          </span>
        }
      />
      <UpdateAuthorityDialog
        updateAuthorityDialogOpen={updateAuthorityDialogOpen}
        setUpdateAuthorityDialogOpen={setUpdateAuthorityDialogOpen}
      />
      <MintAddressDialog
        mintAddressDialogOpen={mintAddressDialogOpen}
        setMintAddressDialogOpen={setMintAddressDialogOpen}
      />
    </Unstable_Grid2>
  );
};

SolanaContractAddress.validationSchema = Yup.object().shape({
  network: Yup.string().required("Required"),
  mintAddress: Yup.string()
    .required("Required")
    .min(32, "Not a valid address.")
    .max(44, "Not a valid address."),
  updateAuthority: Yup.string().required("Required"),
});

SolanaContractAddress.initialValues = (formData: ProjectInfo) => {
  const index = formData.unlockInfo.findIndex((method) => method.type === "solana");
  const unlockInfo = formData.unlockInfo[index] as UnlockInfoSolana;

  return {
    type: UnlockTypes.Solana,
    network: unlockInfo.network || "",
    mintAddress: unlockInfo.mintAddress || "",
    updateAuthority: unlockInfo.updateAuthority || "",
  };
};

export default SolanaContractAddress;
