import { getKy } from "helpers/ky";
import { useState } from "react";

export const useGetContentId = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  const ky = getKy();

  const execute = async (cid: string) => {
    try {
      setIsLoading(true);
      const res: any = await ky(
        `${process.env.NEXT_PUBLIC_MANAGED_API}/content?cidContains=${cid}`
      ).json();
      const data = res.items[0].id;
      setData(data);
      return data;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return { isLoading, data, error, execute };
};
