import { PlaceType } from "../components/Submarine/LockTypes/Location/AddressAutocomplete";

export enum BlockchainOptions {
  Ethereum = "Ethereum",
  Solana = "Solana",
  Polygon = "Polygon",
  Avalanche = "Avalanche",
  Flow = "Flow",
}

export type EMVChains =
  | BlockchainOptions.Ethereum
  | BlockchainOptions.Polygon
  | BlockchainOptions.Avalanche;
export interface UnlockInfoEVM {
  type: UnlockTypes.EVM;
  blockchain: EMVChains;
  network: string;
  contract: string;
  tokenId?: string;
}
export interface UnlockInfoSolana {
  type: UnlockTypes.Solana;
  network: string;
  mintAddress: string;
  updateAuthority: string;
}

export enum FlowNetwork {
  Mainnet = "Mainnet",
  Testnet = "Testnet",
}
export interface UnlockInfoFlow {
  type: UnlockTypes.Flow;
  network: FlowNetwork;
  contract: string;
  tokenId?: string;
}

export type UnlockInfoLocation = {
  type: UnlockTypes.Location;
  lat: number;
  long: number;
  distance: string;
  place?: PlaceType;
};

export type UnlockInfoTwitch = {
  type: UnlockTypes.Twitch;
  loginName: string;
};

export enum UnlockTypes {
  EVM = "evm",
  Solana = "solana",
  Flow = "flow",
  Location = "location",
  Twitch = "twitch",
  NoLock = "noLock",
}
export type UnlockInfoNoLock = {
  type: UnlockTypes.NoLock;
};

export type UnlockInfoNFT = UnlockInfoEVM | UnlockInfoSolana | UnlockInfoFlow;
export type UnlockInfo = UnlockInfoNFT | UnlockInfoLocation | UnlockInfoTwitch | UnlockInfoNoLock;
