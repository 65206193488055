const TwitchIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.77308 0L0 4.52184V22.607H6.49827V26H10.046L13.5903 22.607H18.9081L26 15.8272V0H1.77308ZM4.13532 2.25946H23.6366V14.6945L19.4991 18.6516H13L9.45679 22.04V18.6517H4.13532V2.25946ZM10.6361 13.5655H13V6.78373H10.6361V13.5655ZM17.1358 13.5655H19.4992V6.78373H17.1358V13.5655Z"
        fill="url(#paint0_linear_2088_7830)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2088_7830"
          x1="15.9732"
          y1="20.9363"
          x2="0.68562"
          y2="-6.29748"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2539D0" />
          <stop offset="1" stopColor="#DEA986" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default TwitchIcon;
