import { MenuItem } from "@mui/material";
import { Field } from "formik";
import { Select } from "formik-mui";

export const networkOptions = {
  Ethereum: ["Mainnet", "Goerli"],
  Polygon: ["Mainnet", "Mumbai"],
  Avalanche: ["Mainnet", "Fuji"],
  Solana: ["Mainnet-Beta", "Devnet"],
  Flow: ["Mainnet"],
};

const NetworkSelector = ({ options, type }) => {
  return (
    <Field
      component={Select}
      id={`${type}.network`}
      name={`${type}.network`}
      label="Network"
      inputProps={{
        fullwidth: "true",
      }}
      variant="standard"
    >
      {options.map((o) => (
        <MenuItem key={o} value={o}>
          {o}
        </MenuItem>
      ))}
    </Field>
  );
};

export default NetworkSelector;
