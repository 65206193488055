import { FileType, ProjectInfo } from "@customTypes/ProjectInfo";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import UploadPrivateMedia from "./Upload/UploadPrivateMedia";
import { useFormikContext } from "formik";
import { ContentTO } from "@customTypes/managed/api";
import { Box } from "@mui/material";

const FileStepper = ({ filetype }: { filetype: FileType }) => {
  const { setFieldValue } = useFormikContext<ProjectInfo>();
  const handleFileUpload = (res: ContentTO[]) => {
    setFieldValue("submarineCID", res[0].cid);
  };
  const steps = [`Upload a ${filetype === FileType.File ? "File" : "Folder"}`];
  return (
    <Box sx={{ width: "100%", paddingTop: "10px" }}>
      <Stepper activeStep={0} sx={{ paddingBottom: "1rem" }}>
        {steps.map((label, index) => {
          return (
            <Step key={label} index={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <UploadPrivateMedia uploadType={filetype} onUploadComplete={(res) => handleFileUpload(res)} />
    </Box>
  );
};
export default FileStepper;
