import { Button, Typography } from "@mui/material";
import React from "react";

type Props = {
  label: string;
  icon: JSX.Element;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const TableButton = ({ label, icon, onClick }: Props) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        border: "1px solid #C8C8C8",
        background: (theme) => theme.palette.primary.light,
        padding: "0rem 0.5rem",
        height: "2.5rem",
      }}
    >
      {icon}
      <Typography
        sx={{
          display: {
            sm: "none",
            xs: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default TableButton;
