import { Button } from "@mui/material";
import { useRef } from "react";
import shortUUID from "short-uuid";
import { getKy } from "../../../helpers/ky";

const UploadImagePublic = ({ setIpfsHash, label = "", children, isUploading, setIsUploading }) => {
  const fileInput = useRef(null);
  const onFileChange = async (e, setIsUploading) => {
    const file = e.target.files?.[0];
    const data = new FormData();
    data.append("data", file);
    const filename = file.name;
    const ky = getKy();
    const res = await ky.post("/api/v1/r2/upload", { json: { filename } });
    const body: { signedUrl: string; key: string } = await res.json();
    const { signedUrl, key } = body || {};
    if (!signedUrl) {
      return;
    }
    setIsUploading(true);
    try {
      await fetch(signedUrl, { method: "PUT", body: file, mode: "cors" });
      setIpfsHash(key);
    } catch (err) {}
    setIsUploading(false);

    // @ts-ignore
    fileInput.current.value = null;
  };

  const id = shortUUID.generate();
  return (
    <>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id={id}
        multiple
        type="file"
        ref={fileInput}
        onChange={(e) => onFileChange(e, setIsUploading)}
      />

      <label htmlFor={id}>
        <>
          {children ? (
            children
          ) : (
            <Button variant="outlined" component="span" disabled={isUploading}>
              {label}
            </Button>
          )}
        </>
      </label>
    </>
  );
};

export default UploadImagePublic;
