import Checkbox from "@mui/material/Checkbox";
import { memo, useEffect, useState } from "react";
import { Linkable } from "@customTypes/ProjectInfo";
import {
  Table,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  Unstable_Grid2,
  Box,
  IconButton,
  TableCell,
  CircularProgress,
} from "@mui/material";

import EyeIcon from "components/Dashboard/Table/EyeIcon";
import { File } from "./LinkableStepper";
import { useGetContentId } from "components/Hooks/useGetContentId";
import { useGenerateAccessToken } from "components/Hooks/useGenerateAccessToken";
import { selectGatewayUrl } from "store/selectors/authSelectors";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setAlert } from "store/slices/alertSlice";
import { AlertType } from "components/Alert";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import mime from "mime-types";

export enum Type {
  D = "D",
  F = "F",
}

export const getIconByExtension = (originalName: string = "") => {
  const content_type = mime.lookup(originalName);
  if (!content_type) {
    return <ArticleOutlinedIcon style={{ color: "#6CAFFF" }} />;
  }
  if (content_type.includes("image")) {
    return <PhotoOutlinedIcon style={{ color: "#6CAFFF" }} />;
  }
  if (content_type.includes("video")) {
    return <SlideshowOutlinedIcon style={{ color: "#6CAFFF" }} />;
  }
  if (content_type.includes("audio")) {
    return <HeadphonesOutlinedIcon style={{ color: "#6CAFFF" }} />;
  } else {
    return <ArticleOutlinedIcon style={{ color: "#6CAFFF" }} />;
  }
};

interface ListCIDPros {
  files: File[];
  selectedLinkables: Linkable[];
  setFieldValue: (field: string, value: any) => void;
}
export type LinkableWithOriginalName = Linkable & { originalname: string };

const ListCID = ({ files, selectedLinkables, setFieldValue }: ListCIDPros) => {
  const gatewayUrl = useAppSelector(selectGatewayUrl);
  const dispatch = useAppDispatch();

  const {
    isLoading: isContentIdLoading,
    error: contentIdError,
    execute: contentIdExecute,
  } = useGetContentId();
  const {
    isLoading: isGenerateAccessTokenLoading,
    error: generateAccessTokenError,
    execute: generateAccessTokenExecute,
  } = useGenerateAccessToken();

  if (!selectedLinkables) {
    return null;
  }

  const checkSelectedFileByCID = (cid: string) => {
    return !!selectedLinkables.find((f) => f.submarine_cid === cid);
  };

  const handleToggle = (value: File) => {
    const exists = selectedLinkables.find((f) => f.submarine_cid === value.cid);
    let newChecked = [...selectedLinkables];

    if (!exists) {
      const entry = { submarine_cid: value.cid, order: -1 };
      newChecked.push(entry);
    } else {
      newChecked = newChecked.filter((f) => f.submarine_cid !== value.cid);
    }
    const allCids = newChecked
      .filter((value, index, ar) => ar.indexOf(value) === index)
      .map((item, i) => {
        return { ...item, order: i };
      });
    setFieldValue("linkables", allCids);
  };

  const errorHandler = () => {
    if (!contentIdError || generateAccessTokenError) {
      dispatch(
        setAlert({
          message: "An error has occurred. Please try again.",
          type: AlertType.Error,
        })
      );
    }
  };

  const handleClick = async (value: File) => {
    try {
      const contentIdData = await contentIdExecute(value.cid);
      if (!contentIdData) {
        errorHandler();
        throw new Error("Content id error");
      }
      const generateAccessTokenData = await generateAccessTokenExecute(3000, contentIdData);
      if (!generateAccessTokenData) {
        errorHandler();
        throw new Error("Generate access token error");
      }
      window.open(
        `${gatewayUrl}/ipfs/${value.cid}?accessToken=${generateAccessTokenData}`,
        "_blank"
      );
    } catch (err) {
      console.log(err);
    }
  };

  if (isContentIdLoading || isGenerateAccessTokenLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: 300,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ color: "#2D77F6" }} />
        <Typography>Loading...</Typography>
      </Box>
    );
  }
  return (
    <Table
      sx={{
        marginTop: "1rem",
        width: "100%",
        bgcolor: "background.paper",
      }}
    >
      <TableHead>
        <TableRow
          sx={{
            paddingTop: 0,
          }}
        >
          <TableCell
            sx={{
              paddingLeft: "0",
              maxWidth: "8rem",
            }}
          >
            Name
          </TableCell>
          <TableCell></TableCell>
          <TableCell>Selected</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file) => {
          return (
            <TableRow key={file.cid}>
              <TableCell
                sx={{
                  paddingLeft: "0",
                  maxWidth: "12rem",
                }}
              >
                <Unstable_Grid2
                  container
                  wrap="nowrap"
                  gap="1rem"
                  justifyContent={"left"}
                  alignItems={"center"}
                >
                  {getIconByExtension(file.originalname)}
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 400,
                      color: "#646464",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {truncate(file.originalname, 20)}
                  </Typography>
                </Unstable_Grid2>
              </TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                <IconButton
                  sx={{ backgroundColor: "white", paddingRight: "1em" }}
                  onClick={() => handleClick(file)}
                >
                  <EyeIcon />
                </IconButton>
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                <Checkbox
                  onClick={() => handleToggle(file)}
                  checked={checkSelectedFileByCID(file.cid)}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default memo(ListCID);

const truncate = (str: string, n: number) => {
  if (!str) return "";
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};
