import React, { useState, ChangeEvent, useRef } from "react";
import { FileType } from "@customTypes/ProjectInfo";
import { CircularProgress, Container, Typography, Unstable_Grid2 } from "@mui/material";
import { AlertType } from "components/Alert";
import { getKy } from "helpers/ky";
import { useAppDispatch } from "store/hooks";
import { setAlert } from "store/slices/alertSlice";
import File from "./File";
import Folder from "./Folder";

type UploadPrivateMediaProps = {
  uploadType: FileType;
  onUploadComplete: (res: any) => void;
};

const UploadPrivateMedia = ({ uploadType, onUploadComplete }: UploadPrivateMediaProps) => {
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleFileChange = async (files: FileList | null) => {
    if (!files) return;
    setIsUploading(true);
    const FILE_SIZE_LIMIT = 500000000;
    setSelectedFiles(files);
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > FILE_SIZE_LIMIT) {
        dispatch(
          setAlert({
            type: AlertType.Error,
            message: "File too large, limit is 500MB",
          })
        );
        setIsUploading(false);
        return;
      }
      Object.assign(files[i], {
        preview: URL.createObjectURL(files[i]),
      });
    }
    const data = new FormData();

    Array.from(files).forEach((file) => {
      data.append("files", file);
    });
    data.append("pinToIPFS", "false");

    const ky = getKy();
    try {
      const res = await ky.post(`${process.env.NEXT_PUBLIC_MANAGED_API}/content`, {
        body: data,
        timeout: false,
      });
      const resJson: { items: any[] } = await res.json();
      onUploadComplete(resJson.items);
    } catch (e) {
      console.log(e);
      setSelectedFiles(null);
      dispatch(
        setAlert({
          type: AlertType.Error,
          message: "Something went wrong, please try again",
        })
      );
    }
    setIsUploading(false);
  };

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (files: FileList) => {
    if (files.length > 1) {
      dispatch(
        setAlert({
          type: AlertType.Error,
          message: "Only one file allowed at a time",
        })
      );
      return;
    }
    handleFileChange(files);
  };

  const dropHandler = (ev: React.DragEvent<HTMLElement>) => {
    ev.preventDefault();
    handleDrop(ev.dataTransfer.files);
  };

  if (isUploading) {
    return (
      <Container sx={{ textAlign: "center", padding: 2 }}>
        <Typography variant={"h5"} sx={{ marginBottom: 5 }}>
          Please wait
        </Typography>
        <CircularProgress size={50} />
      </Container>
    );
  }

  return (
    <Unstable_Grid2
      container
      onDrop={dropHandler}
      onDragOver={(ev) => ev.preventDefault()}
      onDragEnter={(ev) => ev.preventDefault()}
      sx={{
        width: "100%",
        height: "5em",
        border: (theme) => `2px dashed #C8C8C8`,
        borderRadius: "8px",
        backgroundColor: (theme) => theme.palette.primary.light,
        alignContent: "center",
        justifyContent: "center",
      }}
      onClick={handleFileInputClick}
    >
      <Unstable_Grid2
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        flexWrap="nowrap"
        sx={{ width: "100%", textAlign: "center" }}
      >
        <Unstable_Grid2 sx={{ width: "100%" }}>
          <Typography sx={{ display: { sm: "block", xs: "none", color: "#C8C8C8" } }}>
            Drag and drop or
          </Typography>
          <Typography
            component="label"
            sx={{
              paddingLeft: "0.25rem",
              color: "primary.main",
            }}
            htmlFor="file-upload-main"
          >
            {uploadType === FileType.File ? (
              <File
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange(e.target.files)}
              />
            ) : (
              <Folder
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange(e.target.files)}
              />
            )}
          </Typography>
        </Unstable_Grid2>
        {selectedFiles && selectedFiles.length > 0 && (
          <Typography variant="body2" sx={{ display: "block" }}>
            {uploadType === FileType.File
              ? selectedFiles?.[0]?.name
              : selectedFiles?.[0]?.webkitRelativePath.split("/")[0]}{" "}
            ({selectedFiles.length} files in folder)
          </Typography>
        )}
      </Unstable_Grid2>
    </Unstable_Grid2>
  );
};

export default UploadPrivateMedia;
