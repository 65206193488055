import { useContext } from "react";
import * as Yup from "yup";
import { FormContext } from "../SubmarineFileForm";
import StepWrapper from "./StepWrapper";
import CustomView from "../../Customization/CustomView";
import { ProjectInfo } from "@customTypes/ProjectInfo";
import { getKy } from "helpers/ky";
import { useAppDispatch } from "store/hooks";
import { setAlert } from "store/slices/alertSlice";
import { AlertType } from "components/Alert";
import { ANALYTICS } from "constants/rudderstack_events";

const validationSchema = Yup.object().shape({
  backgroundCid: Yup.string(),
  buttonColor: Yup.object(),
  buttonTextColor: Yup.object(),
  logoCid: Yup.string(),
  thumbnail: Yup.string(),
});

const CustomizationForm = () => {
  const {
    activeStepIndex,
    setActiveStepIndex,
    setNextStepDisabled,
    setFormData,
    formData,
    isEdit,
  } = useContext(FormContext);
  const dispatch = useAppDispatch();
  const handleSubmit = async (formData: ProjectInfo) => {
    setNextStepDisabled(true);
    const submarinedContent: ProjectInfo = {
      ...formData,
    };
    const ky = getKy();
    await ky(`/api/v1/submarine`, {
      method: isEdit ? "PUT" : "POST",
      json: submarinedContent,
      timeout: false,
    })
      .then((response) => response.json())
      .then((data: { result; shortId }) => {
        formData.shortId = data.shortId;
        setFormData(formData);
        setNextStepDisabled(false);
      })
      .catch(() => {
        setNextStepDisabled(false);
        dispatch(
          setAlert({
            type: AlertType.Error,
            message: "Failed to create locked content!",
          })
        );
      });
  };

  const formikData = {
    initialValues: {
      backgroundCid: formData.customizations?.backgroundCid || undefined,
      buttonColor: formData.customizations?.buttonColor || {
        hex: "#6D57FF",
        hsl: { a: 0, h: 0, l: 0, s: 0 },
        hsv: { a: 0, h: 0, s: 0, v: 0 },
        rgb: { a: 0, b: 0, g: 0, r: 0 },
        source: "",
      },
      buttonTextColor: formData.customizations?.buttonTextColor || {
        hex: "#FFF",
        hsl: { a: undefined, h: undefined, l: undefined, s: undefined },
        hsv: { a: 0, h: 0, s: 0, v: 0 },
        rgb: { a: 0, b: 0, g: 0, r: 0 },
        source: "",
      },
      logoCid: formData.customizations?.logoCid || "",
      thumbnail: formData.customizations?.thumbnail || "",
      socialImage: formData.customizations?.socialImage || "",
    },
    validationSchema,
    onSubmit: (values) => {
      const updatedForm = { ...formData };
      updatedForm.customizations = values;
      setFormData(updatedForm);
      handleSubmit(updatedForm).then(() => {
        window.rudderanalytics?.track(ANALYTICS.SUBMARINE_CREATION.COMPLETED, {
          fileType: updatedForm.type,
          linkableCount: updatedForm?.linkables?.length ? updatedForm.linkables.length : undefined,
          unlockMethod: updatedForm.unlockInfo.map((unlock) => unlock.type).join(";"),
          shortId: updatedForm.shortId,
        });
        setActiveStepIndex(activeStepIndex + 1);
      });
    },
  };
  return (
    <StepWrapper
      formikData={formikData}
      title="Custom branding"
      subtitle="Personalize visuals and colors"
    >
      <CustomView />
    </StepWrapper>
  );
};

export default CustomizationForm;
