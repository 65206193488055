import {
  Avatar,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Unstable_Grid2,
  MenuItem as MenuItemMui,
  Typography,
  Box,
  IconButton,
  Card,
  CardHeader,
  CardActions,
} from "@mui/material";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

import TableCell from "@mui/material/TableCell";
import Image from "next/image";
import { useState } from "react";
import DeleteDialog from "./DeleteModal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeDatePretty } from "../../../helpers/makeDatePretty";
import placeholder from "public/submarine_black.png";
import { UnlockInfo } from "@customTypes/UnlockInfo";
import { Customizations } from "@customTypes/Customizations";
import { ShareButton } from "./ShareButton";
import MenuButtonWrapper from "./MenuButtonWrapper";
import styled from "@emotion/styled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EyeIcon from "./EyeIcon";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import TableButton from "./TableButton";
import { FileType } from "@customTypes/ProjectInfo";
import { useFlags } from "launchdarkly-react-client-sdk";
import LinkableIcon from "./Icons/LinkableIcon";
import { useRouter } from "next/router";

const MenuItem = styled(MenuItemMui)({
  borderBottom: "1px solid #E6E6E6",
});
type LinkTableProps = {
  files: {
    created_at: string | null;
    customizations: Customizations | null;
    description: string;
    id: string;
    name: string;
    pinata_user_id: string;
    short_id: string;
    submarine_cid: string | undefined;
    unlock_info: UnlockInfo[];
    type: FileType;
  }[];
  loadLinks: () => void;
  setSubmarineOpen: (open: boolean) => void;
  setEditShortId: (shortId: string) => void;
};

const LinkTable = ({ files, loadLinks, setSubmarineOpen, setEditShortId }: LinkTableProps) => {
  const [file, setFile] = useState(null);

  const { linkableAnalyticsFlag } = useFlags();

  const [open, setOpen] = useState<boolean>(false);
  const router = useRouter();

  const openDeleteModal = (thisFile) => {
    setFile(thisFile);
    setOpen(true);
  };

  const openEditModal = (thisFile) => {
    setEditShortId(thisFile.short_id);
    setSubmarineOpen(true);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const openAnalytics = (file) => {
    router.push(`/analytics/${file.name}/${file.created_at}/${file.short_id}`);
  };

  return (
    <>
      {isMobile ? (
        <>
          {files.map((file) => {
            return (
              <Card key={file.short_id}>
                <CardHeader
                  sx={{
                    "& .MuiCardHeader-action": {
                      marginTop: "auto",
                      marginBottom: "auto",
                    },
                    "& .MuiCardHeader-title": {
                      fontSize: "1.25rem",
                    },
                    "& .MuiCardHeader-subheader": {
                      fontSize: "0.875rem",
                    },
                    paddingTop: "0.25rem",
                    paddingBottom: "0.25rem",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  avatar={getProjectIcon(file.type)}
                  action={
                    <Unstable_Grid2
                      container
                      alignItems={"center"}
                      direction="column"
                      justifyContent={"center"}
                    >
                      <MenuButtonWrapper label={"More"} icon={<MoreVertIcon />}>
                        <MenuItem onClick={() => openEditModal(file)}>Edit</MenuItem>
                        <MenuItem onClick={() => openDeleteModal(file)}>Delete</MenuItem>
                      </MenuButtonWrapper>
                      {linkableAnalyticsFlag && file?.type === FileType.Linkable && (
                        <CardActions sx={{ padding: 0, paddingTop: "0.25rem" }}>
                          <TableButton
                            label="Analytics"
                            icon={<LeaderboardIcon />}
                            onClick={() => {
                              openAnalytics(file);
                            }}
                          />
                        </CardActions>
                      )}
                      <CardActions sx={{ padding: 0, paddingTop: "0.25rem" }}>
                        <ShareButton shortId={file.short_id} />
                      </CardActions>
                    </Unstable_Grid2>
                  }
                  title={file.name}
                  subheader={getTypeString(file)}
                />
              </Card>
            );
          })}
        </>
      ) : (
        <Table>
          <TableHead>
            <TableRow
              sx={{
                paddingTop: 0,
              }}
            >
              <TableCell
                sx={{
                  minWidth: "10rem",
                  maxWidth: "20rem",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "10rem",
                  maxWidth: "10rem",
                }}
              >
                Type
              </TableCell>
              <TableCell
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "none",
                    xl: "table-cell",
                  },
                }}
              >
                Content Identifier (CID)
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {files.map((file) => {
              return (
                <TableRow key={file.short_id}>
                  <TableCell
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "3rem 1fr",
                      }}
                      justifyContent="start"
                      alignItems="center"
                    >
                      {getProjectIcon(file.type)}
                      <Unstable_Grid2 container direction="column" sx={{ paddingLeft: ".5rem" }}>
                        <a
                          href={`${window.location.origin}/${file.short_id}`}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Unstable_Grid2
                            container
                            wrap="nowrap"
                            flexWrap={"nowrap"}
                            alignItems={"center"}
                            sx={{ cursor: "pointer" }}
                          >
                            <Typography variant="body1">{file.name}</Typography>
                            <IconButton
                              sx={{
                                width: "30px",
                                height: "30px",
                                marginLeft: theme.spacing(1),
                                borderRadius: "8px",
                                padding: "2px",
                                border: "1px solid #C8C8C8",
                                background: (theme) => theme.palette.primary.light,
                              }}
                              color="primary"
                            >
                              <EyeIcon />
                            </IconButton>
                          </Unstable_Grid2>
                        </a>
                        <Typography variant="body2">{makeDatePretty(file.created_at)}</Typography>
                      </Unstable_Grid2>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: "hidden",
                        maxWidth: "10em",
                      }}
                    >
                      {getTypeString(file)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "none",
                        xl: "table-cell",
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "30em",
                      }}
                    >
                      {file.type === FileType.Linkable
                        ? "linkable"
                        : truncate(file.submarine_cid || "", 600)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {linkableAnalyticsFlag && file?.type === FileType.Linkable && (
                      <TableButton
                        label="Analytics"
                        icon={<LeaderboardIcon />}
                        onClick={() => {
                          openAnalytics(file);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <ShareButton shortId={file.short_id} />
                  </TableCell>
                  <TableCell align="right">
                    <MenuButtonWrapper label={"More"} icon={<MoreVertIcon />}>
                      <MenuItem onClick={() => openEditModal(file)}>Edit</MenuItem>
                      <MenuItem onClick={() => openDeleteModal(file)}>Delete</MenuItem>
                    </MenuButtonWrapper>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <DeleteDialog file={file} open={open} setOpen={setOpen} loadLinks={loadLinks} />
    </>
  );
};

export default LinkTable;

function truncate(str, n) {
  if (!str) return "";
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

const getTypeString = (file) => {
  return file.unlock_info[1]
    ? `${getPrettyType(file.unlock_info[0])} + ${getPrettyType(file.unlock_info[1])}`
    : getPrettyType(file.unlock_info[0]);
};

const getPrettyType = (unlockInfo) => {
  const type = unlockInfo.type;
  switch (type) {
    case "evm":
      return "NFT - " + unlockInfo.blockchain;
    case "flow":
      return "NFT - Flow";
    case "solana":
      return "NFT - Solana";
    default:
      return type.charAt(0).toUpperCase() + type.slice(1);
  }
};

const getProjectIcon = (type: string) => {
  if (type === "linkable") {
    return <LinkableIcon />;
  }
  if (type === "folder") {
    return <FolderOutlinedIcon fontSize="large" style={{ color: "#6CAFFF" }} />;
  }
  if (type === "file") {
    return <PhotoOutlinedIcon fontSize="large" style={{ color: "#6CAFFF" }} />;
  }
  // type was added on later stage and there is no way to migrate old data on DB, so we just treat it as file.
  return <PhotoOutlinedIcon fontSize="large" style={{ color: "#6CAFFF" }} />;
};
