import update from "immutability-helper";
import { CardDnD } from "./CardDnD";
import { LinkableWithOriginalName } from "./ListCID";

export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

type ListDnDProps = {
  checkedElements: LinkableWithOriginalName[];
  handleReorder: (files: LinkableWithOriginalName[]) => void;
};

export const ListDnD = ({ checkedElements, handleReorder }: ListDnDProps) => {
  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const newCards = update(checkedElements, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, checkedElements[dragIndex] as any],
      ],
    });
    handleReorder(newCards);
  };
  return (
    <>
      {checkedElements
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((card, i) => (
          <CardDnD
            key={card.submarine_cid}
            length={checkedElements.length}
            index={i}
            id={card.submarine_cid}
            text={truncate(card.originalname, 16)}
            file={card}
            moveCard={moveCard}
          />
        ))}
    </>
  );
};

function truncate(str: string, n: number) {
  if (!str) return "";
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}
