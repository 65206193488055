import { Typography, Tooltip, Unstable_Grid2 } from "@mui/material";
import FormikTextfield from "../../../../Form/FormikTextfield";
import * as Yup from "yup";
import { FlowNetwork, UnlockInfoFlow, UnlockTypes } from "../../../../../types/UnlockInfo";
import NetworkSelector from "../NetworkSelector";
import { ProjectInfo } from "@customTypes/ProjectInfo";

type FlowContractAddressType = React.FC & { validationSchema: Yup.ObjectSchema<any> } & {
  initialValues: (formData: ProjectInfo) => UnlockInfoFlow;
};
const FlowContractAddress: FlowContractAddressType = () => {
  return (
    <Unstable_Grid2 container direction={"column"} sx={{ gap: "1em" }}>
      <Typography variant="h5">Flow NFT Details</Typography>
      <NetworkSelector options={["Mainnet"]} type={"evm"} />
      <FormikTextfield
        type="text"
        name="unlockInfo.contract"
        placeholder="A.1234567890abcdef.Catalog_Name"
        label="Contract Address"
        required
      />
      <Tooltip title="You can use ranges '10-100' or comma-separated values '3,4,5' or a combination of both.">
        <FormikTextfield maxLength={1000} type="text" name="unlockInfo.tokenId" label="Token Id" />
      </Tooltip>
    </Unstable_Grid2>
  );
};

FlowContractAddress.validationSchema = Yup.object().shape({
  blockchain: Yup.string().required("Required"),
  network: Yup.string().required("Required"),
  tokenId: Yup.number().typeError("Token id must be a number"),
  contract: Yup.string()
    .required("Required")
    .test(
      "address-is-valid",
      "Not a valid address.",
      (value: string) => value?.startsWith("0x") && value?.length === 18
    ),
});
// value.startsWith("0x") &&
FlowContractAddress.initialValues = (formData: ProjectInfo) => {
  const index = formData.unlockInfo.findIndex((method) => method.type === "evm");
  const unlockInfo = formData.unlockInfo[index] as UnlockInfoFlow;
  return {
    type: UnlockTypes.Flow,
    network: unlockInfo?.network || FlowNetwork.Mainnet,
    contract: unlockInfo?.contract || "",
  };
};

export default FlowContractAddress;
