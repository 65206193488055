import { getKy } from "helpers/ky";
import { useState } from "react";

export const useGenerateAccessToken = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  const ky = getKy();

  const execute = async (timeoutSeconds: number, id: string) => {
    try {
      setIsLoading(true);
      const body = {
        timeoutSeconds: timeoutSeconds,
        contentIds: [id],
      };

      const kyExtended = ky.extend({
        hooks: {
          beforeRequest: [
            (request) => {
              request.headers.delete("source");
            },
          ],
        },
      });

      const data: any = await kyExtended(
        `${process.env.NEXT_PUBLIC_MANAGED_API}/auth/content/jwt`,
        {
          method: "POST",
          json: body,
        }
      ).json();
      setData(data);
      return data;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return { isLoading, data, error, execute };
};
