import { Unstable_Grid2, ToggleButton, ToggleButtonGroup, Box } from "@mui/material";
import { useFormikContext } from "formik";
import { useContext, useMemo, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { selectGatewayUrl } from "../../../store/selectors/authSelectors";
import { Customizations } from "../../../types/Customizations";
import MainLandingContent from "../../Content/MainLandingContent";
import CustomizeLockScreen from "./CustomizeLockScreen";
import { ProjectInfo } from "@customTypes/ProjectInfo";
import { FormContext } from "../SubmarineForm/SubmarineFileForm";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { UnlockTypes } from "../../../types/UnlockInfo";

const CustomView = () => {
  const { formData } = useContext(FormContext);
  const [alignment, setAlignment] = useState("edit");
  const gatewayUrl = useAppSelector(selectGatewayUrl);
  const { values } = useFormikContext<Customizations>();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const projectInfo: ProjectInfo = {
    type: formData.type,
    name: formData.name,
    description: formData.description,
    unlockInfo: formData.unlockInfo,
    customizations: values,
    submarineCID: formData.submarineCID,
    shortId: formData.shortId,
    linkables: formData.linkables,
  };
  const hasNoLock = useMemo(
    () => projectInfo.unlockInfo.every((info) => info.type === UnlockTypes.NoLock),
    [projectInfo.unlockInfo]
  );

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  return (
    <Unstable_Grid2>
      {isTablet ? (
        <>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{ marginBottom: "1em" }}
          >
            <ToggleButton value="edit">Edit</ToggleButton>
            <ToggleButton value="preview">Preview</ToggleButton>
          </ToggleButtonGroup>

          {alignment === "edit" ? (
            <CustomizeLockScreen hasNoLock={hasNoLock} />
          ) : (
            <MainLandingContent
              gatewayUrl={gatewayUrl}
              projectInfo={projectInfo}
              isPreview={true}
            />
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "minmax(0, 1fr) minmax(0, 386px)",
            gap: (theme) => theme.spacing(2),
          }}
        >
          <CustomizeLockScreen hasNoLock={hasNoLock} />
          <MainLandingContent gatewayUrl={gatewayUrl} projectInfo={projectInfo} isPreview={true} />
        </Box>
      )}
    </Unstable_Grid2>
  );
};

export default CustomView;
