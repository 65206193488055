const Nft = () => {
  return (
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.20399 25H17.5946C19.0736 25 20.2727 23.8061 20.2727 22.3333V9.55228C20.2727 9.19866 20.1316 8.85952 19.8805 8.60948L12.6305 1.39052C12.3794 1.14048 12.0388 1 11.6837 1H4.20399C2.72491 1 1.52588 2.19391 1.52588 3.66667V22.3333C1.52588 23.8061 2.72491 25 4.20399 25Z"
        stroke="url(#paint0_linear_2088_7801)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2088_7801"
          x1="13.0431"
          y1="20.3258"
          x2="-2.62427"
          y2="-1.4753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2539D0" />
          <stop offset="1" stopColor="#DEA986" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Nft;
