import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import ListCID, { LinkableWithOriginalName, Type } from "./ListCID";

import { managedApi } from "store/legacy/fakeAxios";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FileType, Linkable, ProjectInfo } from "@customTypes/ProjectInfo";
import { ListDnD } from "./ListDnD";
import AddCTADesktop from "./AddCTADesktop";
import AddCTAMobile from "./AddCTAMobile";
import { useFormikContext } from "formik";
import UploadPrivateMedia from "../Upload/UploadPrivateMedia";
import { useAppDispatch } from "store/hooks";
import { setAlert } from "store/slices/alertSlice";
import { AlertType } from "components/Alert";
import { FormContext } from "components/Submarine/SubmarineForm/SubmarineFileForm";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const steps = ["Select your files", "Drag to reorder", "Add custom links"];

export type LinkableWithOriginalNameAndOrder = LinkableWithOriginalName & { order: number };

const LinkableStepper = () => {
  const [files, setFiles] = useState<File[]>([]);
  const { values, setFieldValue } = useFormikContext<ProjectInfo>();
  const { setNextStepDisabled } = useContext(FormContext);

  const selectedLinkables = values.linkables || [];
  const loadLinks = async () => {
    const managed = await managedApi.get("content?status=pinned&includeCount=false&limit=1000");
    if (!isContent(managed.data)) {
      return;
    }
    const result: File[] = managed.data.items;
    if (result && result.length > 0) {
      const resultsWithoutFolders = result.filter((f) => f.type !== "D");
      setFiles(resultsWithoutFolders);
    }
    return result;
  };
  useEffect(() => {
    loadLinks();
  }, []);

  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useAppDispatch();
  const [clickedFinished, setClickedFinished] = useState(false);
  const handleNext = () => {
    if (selectedLinkables.length < 2) {
      dispatch(setAlert({ type: AlertType.Error, message: "Please select at least 2 files" }));
      setClickedFinished(false);
    } else if (activeStep === steps.length - 1) {
      setNextStepDisabled(false);
      setClickedFinished(true);
    } else {
      setClickedFinished(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleReorder = (orderedLinkables: LinkableWithOriginalName[]) => {
    const linkables: Linkable[] = orderedLinkables.map((f, i) => {
      return { ...f, submarine_cid: f.submarine_cid, order: i };
    });
    setFieldValue("linkables", linkables);
  };

  const checkedElements: LinkableWithOriginalName[] = selectedLinkables.reduce((acc, next) => {
    const current = files.find((ele) => ele.cid === next.submarine_cid);
    if (current) {
      const item = { ...next, originalname: current.originalname, order: next.order };
      acc.push(item);
    }
    return acc;
  }, [] as LinkableWithOriginalName[]);
  const handleSetActiveStep = (index: number) => {
    setActiveStep(index);
    setClickedFinished(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const visibleSteps = isMobile ? steps.slice(0, activeStep + 1) : steps;
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        sx={{ paddingBottom: "1rem", position: "relative" }}
        orientation={isMobile ? "vertical" : "horizontal"}
      >
        {visibleSteps.map((label, index) => {
          return (
            <Step
              key={label}
              index={index}
              sx={{ cursor: "pointer" }}
              onClick={() => activeStep > index && handleSetActiveStep(index)}
            >
              <StepLabel
                sx={{
                  "& .MuiStepLabel-label": {
                    color: "#9B9B9B",
                    fontWeight: 400,
                    "&.Mui-active": {
                      fontWeight: 700,
                      color: "#212121",
                    },
                    "&.Mui-completed": {
                      fontWeight: 700,
                      color: "#212121",
                    },
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}

        <Button
          onClick={handleNext}
          disabled={clickedFinished}
          sx={{
            position: isMobile ? "absolute" : "relative",
            bottom: isMobile ? "1rem" : "0",
            right: isMobile ? "1rem" : "0",
          }}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Stepper>

      {activeStep === 0 && (
        <>
          <UploadPrivateMedia uploadType={FileType.File} onUploadComplete={() => loadLinks()} />
          <ListCID
            files={files}
            selectedLinkables={selectedLinkables}
            setFieldValue={setFieldValue}
          />
        </>
      )}
      {activeStep === 1 && (
        <DndProvider backend={HTML5Backend}>
          <ListDnD checkedElements={checkedElements} handleReorder={handleReorder}></ListDnD>
        </DndProvider>
      )}
      {activeStep === 2 &&
        (isMobile ? (
          <AddCTAMobile orderedLinkables={checkedElements} setFieldValue={setFieldValue} />
        ) : (
          <AddCTADesktop orderedLinkables={checkedElements} setFieldValue={setFieldValue} />
        ))}
    </Box>
  );
};

function isContent(value: unknown): value is Content {
  return (
    typeof value === "object" &&
    value !== null &&
    (value as any).status === 200 &&
    Array.isArray((value as any).items) &&
    isFileArray((value as any).items)
  );
}

function isFileArray(value: unknown): value is File[] {
  return Array.isArray(value) && value.every((item) => isFile(item));
}

function isFile(value: unknown): value is File {
  return typeof value === "object" && value !== null && typeof (value as any).cid === "string";
}

interface Content {
  status: number;
  totalItems: number;
  items: File[];
}

export interface File {
  id: string;
  createdAt: Date;
  cid: string;
  name: string;
  originalname: string;
  size: string;
  metadata: any;
  type: Type;
  pinToIPFS: boolean;
  uri: string;
}

export default LinkableStepper;
