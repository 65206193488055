import { Box, IconButton } from "@mui/material";
import Stepper from "./Stepper";
import ActiveStep from "./ActiveStep";
import { getKy } from "../../../helpers/ky";
import { createContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { FileType, ProjectInfo } from "@customTypes/ProjectInfo";

export const FormContext = createContext({
  isEdit: false,
  activeStepIndex: 0,
  setActiveStepIndex: (index: number) => {},
  setNextStepDisabled: (nextStepDisabled: boolean) => {},
  nextStepDisabled: false,
  formData: {} as ProjectInfo,
  setFormData: (data: any) => {},
  handleClose: () => {},
});

const leftPanelWidth = "20rem";
const leftPanelHeight = "100vh";

interface SubmarineFileProps {
  shortId: string | undefined;
  handleClose: () => void;
}

const SubmarineFileForm = ({ shortId, handleClose }: SubmarineFileProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const isEdit = !!shortId;
  const [nextStepDisabled, setNextStepDisabled] = useState(true);
  const baseInitialValues = {
    name: "",
    description: "",
    customizations: {},
    submarineCID: "",
    shortId: "",
    unlockInfo: [],
    type: FileType.Linkable,
    linkables: [],
  };
  const [formData, setFormData] = useState<ProjectInfo>(baseInitialValues);
  useEffect(() => {
    setActiveStepIndex(0);
    if (isEdit) {
      const ky = getKy();
      ky(`/api/content/${shortId}`).then((res) =>
        res.json().then((json: ProjectInfo) => {
          const initialValues = {
            unlockInfo: json.unlockInfo,
            name: json.name,
            description: json.description,
            customizations: json.customizations,
            submarineCID: json.submarineCID,
            shortId: json.shortId,
            type: json.type,
            linkables: json.linkables,
          };
          const initialValuesWithoutRetweet = removeRetweet(initialValues);
          setFormData(initialValuesWithoutRetweet);
          setNextStepDisabled(false);
        })
      );
    } else {
      setFormData(baseInitialValues);
    }
  }, [shortId]);

  const handleBackClick = () => {
    if (activeStepIndex > 0) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  };

  const handleCloseWithStepReset = () => {
    handleClose();
    setFormData(baseInitialValues);
    setNextStepDisabled(true);
    setActiveStepIndex(0);
  };

  return (
    <FormContext.Provider
      value={{
        activeStepIndex,
        setActiveStepIndex,
        setNextStepDisabled,
        nextStepDisabled,
        formData,
        setFormData,
        isEdit,
        handleClose: handleCloseWithStepReset,
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: "1em",
          left: "1em",
          zIndex: 100,
          display: {
            xs: "block",
            sm: "none",
          },
        }}
        onClick={handleBackClick}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box
        sx={{
          position: "fixed",
          float: "left",
          width: leftPanelWidth,
          height: leftPanelHeight,
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        <Stepper />
      </Box>
      <Box
        sx={{
          position: "relative",
          width: {
            xs: "100%",
            sm: `calc(100% - ${leftPanelWidth})`,
          },
          left: {
            xs: "0",
            sm: leftPanelWidth,
          },
        }}
      >
        <ActiveStep />
      </Box>
    </FormContext.Provider>
  );
};

export default SubmarineFileForm;

const removeRetweet = (initialValues: any) => {
  const unlockInfo = initialValues.unlockInfo.filter((info: any) => info.type !== "retweet");
  return { ...initialValues, unlockInfo };
};
