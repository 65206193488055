import { Unstable_Grid2, Typography, MenuItem, FormHelperText, IconButton } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import FormikTextfield from "../../../../Form/FormikTextfield";
import InformationCircleIconStyled from "../../InformationCircleIconStyled";
import TokenIdDialog from "./TokenIdDialog";
import * as Yup from "yup";
import { ethers } from "ethers";
import { BlockchainOptions, UnlockInfoEVM, UnlockTypes } from "../../../../../types/UnlockInfo";
import NetworkSelector from "../NetworkSelector";
import { Field, useFormikContext } from "formik";
import { Select } from "formik-mui";
import { ProjectInfo } from "@customTypes/ProjectInfo";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ky from "ky";
import VerifyDialog from "./VerifyDialog";
type EVMContractAddressType = React.FC & { validationSchema: Yup.ObjectSchema<any> } & {
  initialValues: (formData: ProjectInfo) => UnlockInfoEVM;
};

export const networkOptions = {
  Ethereum: ["Mainnet", "Goerli"],
  Polygon: ["Mainnet", "Mumbai"],
  Avalanche: ["Mainnet", "Fuji"],
};

const EVMContractAddress: EVMContractAddressType = () => {
  const [tokenIdDialogOpen, setTokenIdDialogOpen] = useState(false);
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false);
  const { values } = useFormikContext<{ evm: UnlockInfoEVM }>();
  const [isERC1155, setIsERC1155] = useState<boolean>(false);
  const [addressVerified, setAddressVerified] = useState<boolean>(false);

  const networks = useMemo(() => {
    const blockchain = values.evm.blockchain;
    return networkOptions[blockchain];
  }, [values.evm.blockchain]);

  const check1155 = async (contract: string, blockchain: string, network: string) => {
    const result = await ky.post("/api/nft/check1155", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userAddress: contract,
        blockchain: blockchain,
        network: network,
      }),
    });
    const responseBody: { result: boolean } = await result.json();
    return responseBody.result;
  };

  const checkAddress = async (contract: string, blockchain: string, network: string) => {
    const result = await ky.post("/api/nft/checkAddress", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userAddress: contract,
        blockchain: blockchain,
        network: network,
      }),
    });
    const responseBody: { result: boolean } = await result.json();
    return responseBody.result;
  };

  useEffect(() => {
    setIsERC1155(false);
    if (values.evm.contract.length > 40) {
      check1155(values.evm.contract, values.evm.blockchain, values.evm.network)
        .then((result: boolean) => {
          setIsERC1155(result);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    checkAddress(values.evm.contract, values.evm.blockchain, values.evm.network)
      .then((result: boolean) => {
        setAddressVerified(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [values.evm.blockchain, values.evm.contract, values.evm.network]);

  return (
    <Unstable_Grid2 container direction={"column"} sx={{ gap: "1em" }}>
      <Unstable_Grid2 container sx={{ justifyContent: "space-between" }}>
        <Typography variant="h5">EVM NFT Details</Typography>
        <Unstable_Grid2 container>
          <Typography variant="body2">Contract Verified</Typography>
          <CheckCircleIcon
            sx={{ color: addressVerified ? "lightgreen" : "red", marginLeft: ".5rem" }}
          ></CheckCircleIcon>
          <span
            style={{ cursor: "pointer" }}
            aria-label="button"
            onClick={() => setVerifyDialogOpen(true)}
          >
            <InformationCircleIconStyled />
          </span>
        </Unstable_Grid2>
      </Unstable_Grid2>

      <Field
        inputProps={{
          fullwidth: "true",
        }}
        component={Select}
        id="evm.blockchain"
        name="evm.blockchain"
        label="Blockchain"
        variant="standard"
      >
        {["Ethereum", "Polygon", "Avalanche"].map((key) => (
          <MenuItem key={key} value={key}>
            {key}
          </MenuItem>
        ))}
      </Field>
      <NetworkSelector options={networks} type={"evm"} />
      <FormikTextfield type="text" name="evm.contract" label="Contract Address ETH" required />
      <FormikTextfield
        type="text"
        name="evm.tokenId"
        label={isERC1155 ? "Token ID" : "Token ID (optional)"}
        required={isERC1155}
        adornment={
          <span
            style={{ cursor: "pointer" }}
            aria-label="button"
            onClick={() => setTokenIdDialogOpen(true)}
          >
            <InformationCircleIconStyled />
          </span>
        }
      />

      <TokenIdDialog open={tokenIdDialogOpen} setOpen={setTokenIdDialogOpen} />
      <VerifyDialog open={verifyDialogOpen} setOpen={setVerifyDialogOpen} />
    </Unstable_Grid2>
  );
};

EVMContractAddress.validationSchema = Yup.object().shape({
  blockchain: Yup.string().required("Required"),
  network: Yup.string().required("Required"),
  tokenId: Yup.number().typeError("Token id must be a number"),
  contract: Yup.string()
    .required("Required")
    .test("address-is-valid", "Not a valid address.", (value: string) =>
      ethers.utils.isAddress(value)
    ),
});

EVMContractAddress.initialValues = (formData: ProjectInfo) => {
  const index = formData.unlockInfo.findIndex((method) => method.type === "evm");
  const unlockInfo = formData.unlockInfo[index] as UnlockInfoEVM;

  return {
    type: UnlockTypes.EVM,
    blockchain: unlockInfo.blockchain || BlockchainOptions.Ethereum,
    network: unlockInfo.network || "",
    tokenId: unlockInfo.tokenId || "",
    contract: unlockInfo.contract || "",
  };
};

export default EVMContractAddress;
