import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Unstable_Grid2,
} from "@mui/material";
import InformationCircleIconStyled from "../../InformationCircleIconStyled";

export default function VerifyDialog({ open, setOpen }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        "& .MuiDialog-paper": {
          width: "70vw",
          borderRadius: "20px",
          padding: (theme) => theme.spacing(1),
        },
      }}
      maxWidth="md"
    >
      <DialogTitle sx={{ display: "flex", gap: ".5em" }}>
        <InformationCircleIconStyled style={{ color: (theme) => theme.palette.green[100] }} />
        What happens if my contract is not shown as verified?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          If your contract is not shown as verified, that means we could not locate your contract
          with the provided network and blockchain configurations. Please continue at your own
          discretion.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
