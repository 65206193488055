import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import type { Identifier, XYCoord } from "dnd-core";
import { useState } from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppSelector } from "store/hooks";
import { selectGatewayUrl } from "store/selectors/authSelectors";

const style = {
  cursor: "move",
};

export const ItemTypes = {
  CARD: "card",
};

export interface CardProps {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const CardDnD = ({ file, id, text, index, moveCard, length }) => {
  const gatewayUrl = useAppSelector(selectGatewayUrl);
  const [draggingPreview, setDraggingPreview] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const handleDrag = () => {
    setDraggingPreview(true);
  };

  const handleDragEnd = () => {
    setDraggingPreview(false);
  };

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));
  const itemText = !draggingPreview ? `${index + 1}. ${text}` : text;
  return (
    <>
      <span
        onDragStart={handleDrag}
        onDragEnd={handleDragEnd}
        ref={ref}
        style={{ ...style, opacity }}
        data-handler-id={handlerId}
      >
        <ListItem>
          {draggingPreview ? <DragHandleIcon /> : <DragIndicatorIcon />}
          <ListItemText
            primary={itemText}
            sx={{
              overflow: "auto",
              textOverflow: "ellipsis",
            }}
          ></ListItemText>

          <IconButton
            onClick={() => moveCard(index, index - 1)}
            disabled={draggingPreview}
            aria-label="delete"
          >
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton
            onClick={() => moveCard(index, index + 1)}
            disabled={draggingPreview}
            aria-label="delete"
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </ListItem>
      </span>
    </>
  );
};
