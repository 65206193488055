import { useCallback, useEffect, useState } from "react";
import LinkTable from "./Table/LinkTable";
import AppPagination from "./Table/AppPagination";

import Loading from "./Loading";
import { getKy } from "../../helpers/ky";
import { Box, Button, Divider, Typography, Unstable_Grid2 } from "@mui/material";
import SubmarineFileDialog from "components/Submarine/SubmarineForm/SubmarineFileDialog";
import { Stream } from "@cloudflare/stream-react";

const LIMIT = 5;

const Dashboard = () => {
  const [files, setFiles] = useState([]);
  const [data, setData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [editShortId, setEditShortId] = useState<string>();
  const handleChangePage = async (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    const offset = value * LIMIT - LIMIT;
    const fileRange = data.slice(offset, offset + LIMIT);
    setFiles(fileRange);
  };

  const loadLinks = async () => {
    setPage(1);
    const ky = getKy();
    const res = await ky.get(`/api/v1/submarine`);
    const json: any = await res.json();
    if (json && json.length > 0) {
      setCount(Math.ceil(json.length / LIMIT));
      setFiles(json.slice(0, LIMIT));
      setData(json);
    }
    setLoading(false);
    return json;
  };

  useEffect(() => {
    loadLinks();
  }, []);
  // prevent function being recreated on state change
  const handleClose = useCallback((refresh = false) => {
    setDialogOpen(false);
    setEditShortId(undefined);
    loadLinks();
  }, []);

  const handleOpen = useCallback(() => {
    setDialogOpen(true);
    setEditShortId(undefined);
  }, []);
  return (
    <>
      <Unstable_Grid2 container direction={"column"} sx={{ marginTop: "3em" }}>
        <Unstable_Grid2
          container
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
          sx={{
            marginBottom: "1rem",
          }}
        >
          <Unstable_Grid2 container direction={"column"}>
            <Typography variant="h1">Submarined Files</Typography>
            <Typography variant="subtitle2">Find your recently submarined content below</Typography>
          </Unstable_Grid2>
          <Button
            sx={{
              marginTop: "1em",
            }}
            onClick={handleOpen}
          >
            + New Content
          </Button>
        </Unstable_Grid2>
        <Divider
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
            zIndex: -1,
          }}
        />

        {loading ? (
          <Loading />
        ) : (
          <>
            <Unstable_Grid2 container direction={"column"} gap={3}>
              <LinkTable
                files={files}
                loadLinks={loadLinks}
                setSubmarineOpen={setDialogOpen}
                setEditShortId={setEditShortId}
              />
              {data && data.length > 0 && (
                <Unstable_Grid2 sx={{ margin: "auto" }}>
                  <AppPagination page={page} count={count} handleChange={handleChangePage} />
                </Unstable_Grid2>
              )}
            </Unstable_Grid2>
          </>
        )}
      </Unstable_Grid2>
      <SubmarineFileDialog open={dialogOpen} onClose={handleClose} shortId={editShortId} />
    </>
  );
};

export default Dashboard;
