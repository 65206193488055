import { InputAdornment } from "@mui/material";
import { Field, getIn, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { forwardRef } from "react";

const capitalizeFirstLetter = (value) => {
  if (typeof value !== "string") return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const FormikTextfield = (
  { name, label, required = false, type = "text", adornment = <></>, maxLength = 500, ...props },
  ref
) => {
  const formik = useFormikContext();
  const value = getIn(formik.values, name) || "";

  const handleInputChange = (event) => {
    const newValue = capitalizeFirstLetter(event.target.value);
    formik.setFieldValue(name, newValue);
  };

  return (
    <>
      <Field
        variant={props.variant || "outlined"}
        component={TextField}
        name={name}
        label={label}
        type={type}
        required={required}
        error={getIn(formik.touched, name) && Boolean(getIn(formik.errors, name))}
        InputProps={{
          endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
        }}
        inputProps={{
          fullwidth: "true",
          maxLength: maxLength,
          ...props.inputprops,
        }}
        autoComplete="off"
        helperText={formik.touched[name] && formik.errors[name]}
        value={value}
        onChange={handleInputChange}
        {...props}
      />
      <div style={{ height: 0, display: "inline" }} {...props} ref={ref}></div>
      {/* This is a hack to make the ref work */}
    </>
  );
};

export default forwardRef(FormikTextfield);
