import { FileType, ProjectInfo } from "@customTypes/ProjectInfo";
import {
  Button,
  CircularProgress,
  Container,
  Typography,
  Unstable_Grid2,
  IconButton,
  Box,
  TextField,
} from "@mui/material";
import { AlertType } from "components/Alert";
import { getKy } from "helpers/ky";
import { useContext, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setAlert } from "store/slices/alertSlice";
import { FormContext } from "../SubmarineFileForm";
import {
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  TelegramIcon,
  TelegramShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import MainLandingContent from "components/Content/MainLandingContent";
import { selectGatewayUrl } from "store/selectors/authSelectors";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import { ShareToLens, Theme } from "@lens-protocol/widgets-react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EyeIcon from "components/Dashboard/Table/EyeIcon";
import { ANALYTICS } from "constants/rudderstack_events";

const StyledTextField = (props) => (
  <TextField
    disabled
    {...props}
    placeholder={" " + props.placeholder}
    sx={{
      width: "22rem",
      height: "100%",
      "&	.MuiInputBase-root": {
        backgroundColor: "white",
        borderRadius: "100px",
        border: "0px solid red",
      },
      "& .MuiInputBase-adornedEnd": {
        zIndex: 9,
        paddingRight: "0",
      },
      "input:focus::placeholder": {
        color: "transparent",
      },
    }}
    InputProps={{
      endAdornment: (
        <Button onClick={props.onClick}>
          <ContentCopyIcon />
          Copy link
        </Button>
      ),
    }}
  ></TextField>
);

const ShareYourProject = () => {
  const { formData, handleClose } = useContext(FormContext);
  const shortId = formData.shortId || "1";
  const dispatch = useAppDispatch();
  const gatewayUrl = useAppSelector(selectGatewayUrl);
  const url = `${window.location.origin}/${shortId}`;
  const via = "PinataCloud";
  const hashtags = ["submarine", "pinatacloud"];
  const title = "Check out my unlockable content made with Submarine!";
  const isNoLock = formData?.unlockInfo[0].type === "noLock";

  const copyLink = (shortId) => {
    navigator.clipboard.writeText(`${window.location.origin}/${shortId}`);
    sendEvent("copyLink");
    dispatch(
      setAlert({
        type: AlertType.Info,
        message: "Share link copied!",
      })
    );
  };

  return (
    <Unstable_Grid2
      container
      sx={{
        padding: "2rem 3rem",
        position: "relative",
        gap: "1rem",
        marginBottom: "5rem",
        marginTop: "2rem",
      }}
      direction="column"
    >
      <>
        <Unstable_Grid2>
          <Typography variant="h3">Share your creation 🎉</Typography>
          <Typography variant="body1" sx={{ opacity: 0.5 }}>{`Your ${
            formData.type === FileType.Linkable ? "linkable" : "content"
          } is now published and ready to be shared.`}</Typography>
        </Unstable_Grid2>
        <Unstable_Grid2 container alignItems={"center"}>
          <IconButton sx={{ backgroundColor: "white" }} onClick={() => window.open(url)}>
            <EyeIcon />
          </IconButton>
          <Typography sx={{ marginLeft: "1rem" }} variant={"body1"}>
            {formData.name}
          </Typography>
        </Unstable_Grid2>
        <MainLandingContent projectInfo={formData} gatewayUrl={gatewayUrl} isPreview={true} />
        <StyledTextField
          placeholder={removeProtocol(url)}
          onClick={(e) => {
            e.stopPropagation();
            copyLink(shortId);
          }}
        />
        <Unstable_Grid2 container gap={".5rem"}>
          <Box
            sx={{
              "& p": {
                display: "none !important",
              },
              "& button": {
                height: "35px",
                width: "35px",
                padding: "0 !important",
              },
            }}
            onClick={() => sendEvent("lens")}
          >
            <ShareToLens content={url} hashtags={"submarine, pinataCloud"} theme={Theme["mint"]} />
          </Box>
          <IconButton
            sx={{ backgroundColor: "#0075FF", height: "35px", width: "35px" }}
            disableRipple
            onClick={() => copyLink(shortId)}
          >
            <ShareRoundedIcon sx={{ color: "white" }}></ShareRoundedIcon>
          </IconButton>
          <TwitterShareButton
            title={title}
            via={via}
            hashtags={hashtags}
            url={url}
            onClick={() => sendEvent("twitter")}
          >
            <TwitterIcon size={35} round />
          </TwitterShareButton>
          <LinkedinShareButton url={url} onClick={() => sendEvent("linkedin")}>
            <LinkedinIcon round size={35}></LinkedinIcon>
          </LinkedinShareButton>
          <FacebookShareButton
            quote={title}
            hashtag={"#submarine"}
            url={url}
            onClick={() => sendEvent("facebook")}
          >
            <FacebookIcon round size={35}></FacebookIcon>
          </FacebookShareButton>
          <RedditShareButton title={title} url={url} onClick={() => sendEvent("reddit")}>
            <RedditIcon round size={35}></RedditIcon>
          </RedditShareButton>
          <TelegramShareButton title={title} url={url} onClick={() => sendEvent("telegram")}>
            <TelegramIcon round size={35}></TelegramIcon>
          </TelegramShareButton>
          <EmailShareButton url={url} onClick={() => sendEvent("email")}>
            <EmailIcon round size={35}></EmailIcon>
          </EmailShareButton>
        </Unstable_Grid2>
      </>
      <Button onClick={handleClose} sx={{ marginLeft: "auto" }}>
        Close
      </Button>
    </Unstable_Grid2>
  );
};

export default ShareYourProject;

const removeProtocol = (url) => {
  // remove http or https
  return url.replace(/(^http|https):\/\//, "");
};

const sendEvent = (type) => {
  window.rudderanalytics?.track(ANALYTICS.SUBMARINE_CREATION.SHARE_CLICK, {
    shareType: type,
  });
};
