const LinkableIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.09545 21.1841V16.3386L11.9955 18.7614L8.09545 21.1841ZM14.1818 9.89773C13.279 9.89773 12.5117 9.58141 11.8797 8.94879C11.2478 8.31618 10.9318 7.548 10.9318 6.64424C10.9318 5.74051 11.2478 4.97348 11.8797 4.34318C12.5117 3.71288 13.279 3.39773 14.1818 3.39773H15.3636V4.57955H14.1818C13.6073 4.57955 13.119 4.78028 12.7169 5.18174C12.3147 5.58322 12.1136 6.07072 12.1136 6.64424C12.1136 7.21778 12.3147 7.70644 12.7169 8.11023C13.119 8.51402 13.6073 8.71591 14.1818 8.71591H15.3636V9.89773H14.1818ZM17.7273 9.89773V8.71591H18.9091C19.4836 8.71591 19.9719 8.51518 20.374 8.11371C20.7762 7.71223 20.9773 7.22473 20.9773 6.65121C20.9773 6.07768 20.7762 5.58902 20.374 5.18523C19.9719 4.78144 19.4836 4.57955 18.9091 4.57955H17.7273V3.39773H18.9091C19.8119 3.39773 20.5792 3.71404 21.2112 4.34667C21.8431 4.97928 22.1591 5.74746 22.1591 6.65121C22.1591 7.55495 21.8431 8.32197 21.2112 8.95227C20.5792 9.58258 19.8119 9.89773 18.9091 9.89773H17.7273ZM14.4773 7.23864V6.05682H18.6136V7.23864H14.4773ZM18.1114 15.0682V12.7045H24.2273V1.77273H8.86364V12.7045H7.09091V1.77273C7.09091 1.28523 7.26449 0.867898 7.61165 0.520739C7.95881 0.17358 8.37614 0 8.86364 0H24.2273C24.7148 0 25.1321 0.17358 25.4793 0.520739C25.8264 0.867898 26 1.28523 26 1.77273V13.2955C26 13.783 25.8264 14.2003 25.4793 14.5474C25.1321 14.8946 24.7148 15.0682 24.2273 15.0682H18.1114ZM1.77273 26C1.28523 26 0.867898 25.8264 0.520739 25.4793C0.17358 25.1321 0 24.7148 0 24.2273V13.2955C0 12.808 0.17358 12.3906 0.520739 12.0435C0.867898 11.6963 1.28523 11.5227 1.77273 11.5227H17.1364C17.6239 11.5227 18.0412 11.6963 18.3884 12.0435C18.7355 12.3906 18.9091 12.808 18.9091 13.2955V24.2273C18.9091 24.7148 18.7355 25.1321 18.3884 25.4793C18.0412 25.8264 17.6239 26 17.1364 26H1.77273ZM1.77273 24.2273H17.1364V13.2955H1.77273V24.2273Z"
        fill="#6CAFFF"
      />
    </svg>
  );
};

export default LinkableIcon;
