import { ProjectInfo } from "@customTypes/ProjectInfo";
import { UnlockInfoTwitch, UnlockTypes } from "@customTypes/UnlockInfo";
import { Typography, Unstable_Grid2 } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import FormikTextfield from "../../../Form/FormikTextfield";

const TwitchForm: React.FC & { validationSchema: Yup.ObjectSchema<any> } & {
  initialValues: (formData: ProjectInfo) => UnlockInfoTwitch;
} = () => {
  return (
    <Unstable_Grid2 container direction={"column"} sx={{ gap: "1em", marginTop: "2em" }}>
      <Typography variant="h5">Twitch Details</Typography>
      <FormikTextfield
        type="text"
        name="twitch.loginName"
        label="Twitch channel"
        required
        autoComplete="off"
      />
    </Unstable_Grid2>
  );
};
TwitchForm.validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  loginName: Yup.string().required("Required"),
});

TwitchForm.initialValues = (formData: ProjectInfo) => {
  const index = formData.unlockInfo.findIndex((method) => method.type === "twitch");
  const unlockInfo = formData.unlockInfo[index] as UnlockInfoTwitch;

  return {
    type: UnlockTypes.Twitch,
    loginName: unlockInfo.loginName || "",
  };
};

export default TwitchForm;
