import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  Typography,
  Box,
  StepContent,
} from "@mui/material";
import { useContext } from "react";
import { FormContext } from "./SubmarineFileForm";
import FolderIcon from "./Steps/UnlockIcons/Folder";

const Stepper = () => {
  const steps = [
    {
      label: "Content Details",
      subtext: "Upload and customize your content.",
    },
    {
      label: "Access Preferences",
      subtext: "Select options to control the visibility of your content.",
    },
    {
      label: "Unlock Settings",
      subtext: "Configure access privileges for your audience.",
    },
    {
      label: "Custom Branding",
      subtext: "Personalize visuals and colors.",
    },
    {
      label: "Share Content",
      subtext: "Engage your audience with your new content.",
    },
  ];
  const { activeStepIndex } = useContext(FormContext);
  return (
    <Box
      sx={{
        alignItems: "left",
        padding: "3rem",
        gap: "2em",
        backgroundColor: "#F0F0F0",
        height: "100%",
        display: {
          xs: "none",
          sm: "flex",
        },
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <Box
          sx={{
            width: "48px",
            height: "48px",
            background: "linear-gradient(330.59deg, #D6D6D6 5.88%, #FFFFFF 107.63%)",
            mixBlendMode: "normal",
            border: "1.5px solid #AFAFAF",
            boxShadow: "0px 3.59286px 12.575px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <FolderIcon />
          </Box>
        </Box>
        <Typography variant="h1">New Project</Typography>
      </Box>
      <MuiStepper activeStep={activeStepIndex} orientation="vertical">
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>
              <Typography variant="h6">{step.label}</Typography>
            </StepLabel>
            <StepContent>
              <Typography variant="body2" fontWeight={400} sx={{ color: "grey " }}>
                {step.subtext}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </MuiStepper>
    </Box>
  );
};
export default Stepper;
