import {
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  TelegramIcon,
  TelegramShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";

import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import { ShareToLens, Theme, Size } from "@lens-protocol/widgets-react";
import { Box, IconButton, MenuItem as MenuItemMui, MenuList, Unstable_Grid2 } from "@mui/material";
import { setAlert } from "store/slices/alertSlice";
import { useAppDispatch } from "store/hooks";
import { AlertType } from "components/Alert";
import { MenuButtonWrapper } from "./MenuButtonWrapper";
import ShareIcon from "@mui/icons-material/Share";
import styled from "@emotion/styled";
import { ANALYTICS } from "constants/rudderstack_events";
type ShareButtonProps = {
  shortId: string;
};

const MenuItem = styled(MenuItemMui)({
  borderBottom: "1px solid #E6E6E6",
});
export const ShareButton = ({ shortId }: ShareButtonProps) => {
  const dispatch = useAppDispatch();
  const copyLink = (shortId) => {
    sendEvent("copyLink");
    navigator.clipboard.writeText(`${window.location.origin}/${shortId}`);
    dispatch(
      setAlert({
        type: AlertType.Info,
        message: "Share link copied!",
      })
    );
  };
  const url = `${window.location.origin}/${shortId}`;
  const via = "PinataCloud";
  const hashtags = ["submarine", "pinatacloud"];
  const title = "Check out my unlockable content made with Submarine!";

  return (
    <MenuButtonWrapper label={"Share"} icon={<ShareIcon />}>
      <MenuItem
        // magic css to hide the default p tag on ShareToLens
        sx={{
          "& p": {
            display: "none !important",
          },
          "& button": {
            height: "35px",
            width: "35px",
            padding: "0 !important",
          },
        }}
        onClick={() => sendEvent("lens")}
      >
        <ShareMenuItemWrapper>
          <ShareToLens content={url} hashtags={"submarine, pinataCloud"} theme={Theme["mint"]} />
          <span>Lens</span>
        </ShareMenuItemWrapper>
      </MenuItem>
      <MenuItem onClick={() => copyLink(shortId)}>
        <ShareMenuItemWrapper>
          <IconButton
            sx={{ backgroundColor: "#0075FF", height: "35px", width: "35px" }}
            disableRipple
          >
            <ShareRoundedIcon sx={{ color: "white" }}></ShareRoundedIcon>
          </IconButton>
          <span>Copy Link</span>
        </ShareMenuItemWrapper>
      </MenuItem>
      <TwitterShareButton
        title={title}
        via={via}
        hashtags={hashtags}
        url={url}
        style={{ width: "100%" }}
        onClick={() => sendEvent("twitter")}
      >
        <MenuItem>
          <ShareMenuItemWrapper>
            <TwitterIcon size={35} round />
            <span>Twitter</span>
          </ShareMenuItemWrapper>
        </MenuItem>
      </TwitterShareButton>
      <LinkedinShareButton
        url={url}
        style={{ width: "100%" }}
        onClick={() => sendEvent("linkedin")}
      >
        <MenuItem>
          <ShareMenuItemWrapper>
            <LinkedinIcon round size={35}></LinkedinIcon>
            <span>LinkedIn</span>
          </ShareMenuItemWrapper>
        </MenuItem>
      </LinkedinShareButton>
      <FacebookShareButton
        quote={title}
        hashtag={"#submarine"}
        url={url}
        style={{ width: "100%" }}
        onClick={() => sendEvent("facebook")}
      >
        <MenuItem>
          <ShareMenuItemWrapper>
            <FacebookIcon round size={35}></FacebookIcon>
            <span>Facebook</span>
          </ShareMenuItemWrapper>
        </MenuItem>
      </FacebookShareButton>
      <RedditShareButton
        title={title}
        url={url}
        style={{ width: "100%" }}
        onClick={() => sendEvent("reddit")}
      >
        <MenuItem>
          <ShareMenuItemWrapper>
            <RedditIcon round size={35}></RedditIcon>
            <span>Reddit</span>
          </ShareMenuItemWrapper>
        </MenuItem>
      </RedditShareButton>
      <TelegramShareButton
        title={title}
        url={url}
        style={{ width: "100%" }}
        onClick={() => sendEvent("telegram")}
      >
        <MenuItem>
          <ShareMenuItemWrapper>
            <TelegramIcon round size={35}></TelegramIcon>
            <span>Telegram</span>
          </ShareMenuItemWrapper>
        </MenuItem>
      </TelegramShareButton>

      <EmailShareButton url={url} style={{ width: "100%" }} onClick={() => sendEvent("email")}>
        <MenuItem>
          <ShareMenuItemWrapper>
            <EmailIcon round size={35}></EmailIcon>
            <span>Email</span>
          </ShareMenuItemWrapper>
        </MenuItem>
      </EmailShareButton>
    </MenuButtonWrapper>
  );
};

export default ShareButton;

const ShareMenuItemWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
      }}
    >
      {children}
    </Box>
  );
};

const sendEvent = (type) => {
  window.rudderanalytics?.track(ANALYTICS.SUBMARINE_CREATION.SHARE_CLICK, {
    shareType: type,
  });
};
