import { Box, Card, IconButton, Typography, Unstable_Grid2 } from "@mui/material";
import { useContext, useState } from "react";
import { FormContext } from "../SubmarineFileForm";
import { UnlockTypes } from "../../../../types/UnlockInfo";
import StepWrapper from "./StepWrapper";
import LockTypeCheckbox from "./LockTypeCheckbox";
import * as Yup from "yup";
import TwitchIcon from "./UnlockIcons/Twitch";
import LocationIcon from "./UnlockIcons/Location";
import NftIcon from "./UnlockIcons/Nft";
import NoLockIcon from "./UnlockIcons/NoLock";
import { ANALYTICS } from "constants/rudderstack_events";
import PublicIcon from "./UnlockIcons/Public";
import LockedIcon from "./UnlockIcons/Locked";
import styled from "@emotion/styled";
import { useFormikContext } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

enum Step {
  First,
  Last,
}

const CardStyled = styled(Card)`
  padding: 1em;
  border: 1px solid #c8c8c8;
  box-shadow: 1.9255px 5.7765px 19.255px 2.88825px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;
const BoxStyled = styled(Box)`
  display: grid;
  grid-template-columns: 54px 1fr 1.5rem;
  gap: 0.5rem;
  align-items: center;
`;

const LockTypeSelect = () => {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(FormContext);

  const [step, setStep] = useState<Step>(Step.First);

  const initialMethods: UnlockTypes[] = formData.unlockInfo.map((method) => method.type);
  const isDefault = initialMethods.length === 0;
  const selectedMethods: UnlockTypes[] = isDefault ? [UnlockTypes.NoLock] : initialMethods || [];
  const formikData = {
    initialValues: { selectedMethods },
    onSubmit: (values) => {
      const unlockInfo = getUnlockInfo(values.selectedMethods, formData.unlockInfo);
      const data = { ...formData, unlockInfo };
      setFormData(data);

      window.rudderanalytics?.track(ANALYTICS.SUBMARINE_CREATION.UNLOCK_SELECTED, {
        fileType: data.type,
        linkableCount: data?.linkables?.length ? data?.linkables?.length : undefined,
        unlockMethod: data.unlockInfo.map((unlock) => unlock.type).join(";"),
      });
      const isNoLock = data?.unlockInfo[0]?.type === "noLock";
      if (isNoLock) {
        setActiveStepIndex(activeStepIndex + 2);
      } else {
        setActiveStepIndex(activeStepIndex + 1);
      }
    },
    validationSchema: Yup.object().shape({
      selectedMethods: Yup.array()
        .min(1, "Please select at least one method")
        .max(2, "Please select a maximum of 2 methods"),
    }),
  };

  const subtitle =
    step === Step.First
      ? "Choose whether to gate your content or make it public"
      : "Select up to two methods to unlock content";
  return (
    <StepWrapper formikData={formikData} title="Select Unlock Methods" subtitle={subtitle}>
      {step === Step.First ? (
        <PublicOrLocked setStep={setStep} />
      ) : (
        <LockOptions setStep={setStep} />
      )}
    </StepWrapper>
  );
};

export default LockTypeSelect;

type TypeDetails = {
  title?: string;
  locks: {
    label: string;
    description: string;
    type: UnlockTypes;
  }[];
}[];

const PublicOrLocked = ({ setStep }) => {
  const { values, setFieldValue } = useFormikContext<{ selectedMethods: UnlockTypes[] }>();
  const isNoLock = values.selectedMethods[0] === UnlockTypes.NoLock;
  if (values.selectedMethods.length > 0 && !isNoLock) {
    setStep(Step.Last);
  }
  return (
    <Unstable_Grid2 container direction="column" sx={{ gap: "1rem" }}>
      <CardStyled
        sx={{
          border: "2px solid #0175FF !important",
        }}
      >
        <BoxStyled>
          <UnlockIcon type={"public"} />
          <Unstable_Grid2>
            <Typography sx={{ fontSize: "0.85rem", fontWeight: 900 }}>Public</Typography>
            <Typography sx={{ fontSize: ".75rem", fontWeight: 600, opacity: 0.5 }}>
              Content is accessible to everyone
            </Typography>
          </Unstable_Grid2>
        </BoxStyled>
      </CardStyled>
      <CardStyled
        onClick={() => {
          setFieldValue("selectedMethods", []);
          setStep(Step.Last);
        }}
      >
        <BoxStyled>
          <UnlockIcon type={"locked"} />
          <Unstable_Grid2>
            <Typography sx={{ fontSize: "0.85rem", fontWeight: 900 }}>Locked</Typography>
            <Typography sx={{ fontSize: ".75rem", fontWeight: 600, opacity: 0.5 }}>
              Gated content and custom access
            </Typography>
          </Unstable_Grid2>
        </BoxStyled>
      </CardStyled>
    </Unstable_Grid2>
  );
};

const LockOptions = ({ setStep }) => {
  const typeDetails: TypeDetails = [
    {
      locks: [
        {
          label: "Twitch",
          description: "Subscribing to a Twitch streamer unlocks access",
          type: UnlockTypes.Twitch,
        },
        {
          label: "Location",
          description: "Geolocation based access",
          type: UnlockTypes.Location,
        },
      ],
    },
    {
      title: "NFT Ownership",
      locks: [
        {
          label: "EVM-based",
          description: "Owning an EVM-based NFT unlocks access",
          type: UnlockTypes.EVM,
        },
        {
          label: "Flow Blockchain",
          description: "Owning a Flow blockchain NFT unlocks access",
          type: UnlockTypes.Flow,
        },
        {
          label: "Solana Blockchain",
          description: "Owning a Solana blockchain NFT unlocks access",
          type: UnlockTypes.Solana,
        },
      ],
    },
  ];

  const { setFieldValue } = useFormikContext<{ selectedMethods: UnlockTypes[] }>();
  return (
    <Unstable_Grid2 container direction="column" sx={{ gap: "1rem" }}>
      <IconButton
        onClick={() => {
          setFieldValue("selectedMethods", ["noLock"]);
          setStep(Step.First);
        }}
        sx={{
          position: "absolute",
          opacity: 0.5,
          top: 75,
          left: 0,
        }}
      >
        <Unstable_Grid2 container gap={"0.5rem"} alignItems="center">
          <ArrowBackIcon />
          <Typography>Back</Typography>
        </Unstable_Grid2>
      </IconButton>
      {typeDetails.map((group) => {
        return (
          <>
            <Typography variant={"h5"}>{group.title}</Typography>
            {group.locks.map((method) => (
              <CardStyled key={method.type}>
                <BoxStyled>
                  <UnlockIcon type={method.type} />
                  <Unstable_Grid2>
                    <Typography sx={{ fontSize: "0.85rem", fontWeight: 900 }}>
                      {method.label}
                    </Typography>
                    <Typography sx={{ fontSize: ".75rem", fontWeight: 600, opacity: 0.5 }}>
                      {method.description}
                    </Typography>
                  </Unstable_Grid2>
                  <LockTypeCheckbox method={method} />
                </BoxStyled>
              </CardStyled>
            ))}
          </>
        );
      })}
    </Unstable_Grid2>
  );
};

// should retrieve existing unlockInfo based on the selectedMethods
const getUnlockInfo = (selectedMethods: UnlockTypes[], unlockInfo) => {
  const newUnlockInfo = selectedMethods.map((method) => {
    const existingUnlockInfo = unlockInfo.find((info) => info.type === method);
    if (existingUnlockInfo) {
      return existingUnlockInfo;
    }
    return { type: method };
  });
  return newUnlockInfo;
};

type UnlockIconProps = {
  type: UnlockTypes | "public" | "locked";
};
const UnlockIcon = ({ type }: UnlockIconProps) => {
  let Icon;
  switch (type) {
    case UnlockTypes.NoLock:
      Icon = NoLockIcon;
      break;
    case UnlockTypes.Twitch:
      Icon = TwitchIcon;
      break;
    case UnlockTypes.Location:
      Icon = LocationIcon;
      break;
    case UnlockTypes.EVM:
      Icon = NftIcon;
      break;
    case UnlockTypes.Flow:
      Icon = NftIcon;
      break;
    case UnlockTypes.Solana:
      Icon = NftIcon;
      break;
    case "locked":
      Icon = LockedIcon;
      break;
    case "public":
      Icon = PublicIcon;
      break;
    default:
      Icon = NftIcon;
  }

  return (
    <Box
      sx={{
        width: "48px",
        height: "48px",
        background: "linear-gradient(330.59deg, #D6D6D6 5.88%, #FFFFFF 107.63%)",
        mixBlendMode: "normal",
        border: "1.5px solid #AFAFAF",
        boxShadow: "0px 3.59286px 12.575px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Icon />
    </Box>
  );
};
