import { Box, Tab, Tabs, Typography, Unstable_Grid2 } from "@mui/material";
import { useFormikContext } from "formik";
import type { ProjectInfo } from "@customTypes/ProjectInfo";
import { FileType } from "@customTypes/ProjectInfo";

import MediaLinkSvg from "./Icons/MediaLinkIcon";
import ImageIcon from "./Icons/ImageIcon";
import FolderIcon from "./Icons/FolderIcon";
import { useContext } from "react";
import { FormContext } from "../../SubmarineFileForm";

const FileTypeTab = () => {
  const { values, setFieldValue } = useFormikContext<ProjectInfo>();
  const { setNextStepDisabled } = useContext(FormContext);

  const value = values.type;
  const handleChange = (event: React.SyntheticEvent, newValue: FileType) => {
    setFieldValue("type", newValue);
    setNextStepDisabled(newValue === FileType.Linkable);
  };

  const LinkableLabel = (
    <Unstable_Grid2 container gap={1}>
      <MediaLinkSvg />
      LINKABLE
    </Unstable_Grid2>
  );
  const FileLabel = (
    <Unstable_Grid2 container gap={1}>
      <ImageIcon />
      FILE
    </Unstable_Grid2>
  );
  const FolderLabel = (
    <Unstable_Grid2 container gap={1}>
      <FolderIcon />
      FOLDER
    </Unstable_Grid2>
  );

  return (
    <Box sx={{ color: "#646464" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={LinkableLabel} value={FileType.Linkable} />
          <Tab label={FileLabel} value={FileType.File} />
          <Tab label={FolderLabel} value={FileType.Folder} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={FileType.Linkable}>
        Generate content feeds with customized links for easy sharing across platforms.
      </TabPanel>
      <TabPanel value={value} index={FileType.File}>
        Upload a file and allow users to unlock exclusive access
      </TabPanel>
      <TabPanel value={value} index={FileType.Folder}>
        Upload a folder and allow users to unlock exclusive access
      </TabPanel>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box sx={{ padding: "1rem 0 1rem 0" }}>
          <Typography variant="body1" sx={{ lineHeight: "16px", color: "#646464" }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default FileTypeTab;
