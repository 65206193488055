import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Unstable_Grid2,
} from "@mui/material";
import InformationCircleIconStyled from "../../InformationCircleIconStyled";

export default function TokenIdDialog({ open, setOpen }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        "& .MuiDialog-paper": {
          width: "70vw",
          borderRadius: "20px",
          padding: (theme) => theme.spacing(1),
        },
      }}
      maxWidth="md"
    >
      <DialogTitle sx={{ display: "flex", gap: ".5em" }}>
        <InformationCircleIconStyled style={{ color: (theme) => theme.palette.green[100] }} />
        What happens if I provide a token ID?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          If a token ID is given, we will verify a wallet owns the NFT from the specified collection
          and the token ID matches the one provided.
        </DialogContentText>
        <DialogContentText sx={{ color: "red" }}>
          *Required for ERC-1155 token standard, optional for ERC-721 token standard.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
