import {
  Table,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  Unstable_Grid2,
  TableCell,
  IconButton,
  TextField,
} from "@mui/material";

import { useEffect, useState } from "react";
import { LinkableWithOriginalNameAndOrder } from "./LinkableStepper";
import AddIcon from "@mui/icons-material/Add";
import Cross from "@mui/icons-material/Close";
import withAddCTA, { IWithAddCTA } from "./WithAddCTA";
import { getIconByExtension } from "./ListCID";

const AddCTAMobile = ({
  handleBlur,
  handleUrlChange,
  handleLabelChange,
  handleClear,
  labels,
  urls,
  orderedLinkables,
}: IWithAddCTA) => {
  return (
    <Table>
      <TableBody>
        {orderedLinkables.map((value) => (
          <TableRow key={value.submarine_cid}>
            <CTARow
              value={value}
              {...{ handleBlur, handleUrlChange, handleLabelChange, labels, handleClear, urls }}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default withAddCTA(AddCTAMobile);

const CTARow = ({
  value,
  handleBlur,
  handleUrlChange,
  handleLabelChange,
  handleClear,
  labels,
  urls,
}: Omit<IWithAddCTA, "orderedLinkables"> & { value: LinkableWithOriginalNameAndOrder }) => {
  const [displayCTAForm, setDisplay] = useState(!labels[value.submarine_cid]);
  useEffect(() => {
    setDisplay(!!labels[value.submarine_cid]);
  }, [labels[value.submarine_cid]]);
  return (
    <TableCell>
      <Unstable_Grid2
        container
        direction={"column"}
        justifyContent={"left"}
        alignItems={"flex-start"}
      >
        <Unstable_Grid2
          container
          wrap="nowrap"
          gap="1rem"
          justifyContent={"left"}
          alignItems={"center"}
          sx={{
            width: "100%",
          }}
        >
          {getIconByExtension(value.originalname)}
          <Typography
            variant="body1"
            sx={{
              textOverflow: "ellipsis",
            }}
          >
            {truncate(value.originalname, 20)}
          </Typography>
          {displayCTAForm ? (
            <IconButton
              sx={{
                marginLeft: "auto",
                borderRadius: "0.5rem",
              }}
              onClick={() => {
                setDisplay(false);
                handleClear(value.submarine_cid);
              }}
            >
              <Cross />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                color: "primary.main",
                borderRadius: "0.5rem",
              }}
              onClick={() => {
                setDisplay(true);
              }}
            >
              <AddIcon />
              <Typography
                sx={{
                  color: "primary.main",
                }}
              >
                Add Link
              </Typography>
            </IconButton>
          )}
        </Unstable_Grid2>
        {displayCTAForm && (
          <Unstable_Grid2
            container
            wrap="nowrap"
            justifyContent={"left"}
            direction="column"
            alignItems={"flex-start"}
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                width: "5rem",
              }}
            >
              Link title
            </Typography>
            <TextField
              fullWidth
              value={labels[value.submarine_cid]}
              name={value.submarine_cid}
              onChange={handleLabelChange}
              onBlur={handleBlur}
            />

            <Typography
              variant="body2"
              sx={{
                width: "5rem",
              }}
            >
              Link url
            </Typography>
            <TextField
              fullWidth
              value={urls[value.submarine_cid]}
              name={value.submarine_cid}
              onChange={handleUrlChange}
              onBlur={handleBlur}
            />
          </Unstable_Grid2>
        )}
      </Unstable_Grid2>
    </TableCell>
  );
};

function truncate(str: string, n: number) {
  if (!str) return "";
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}
