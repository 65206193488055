import { FileType, ProjectInfo } from "@customTypes/ProjectInfo";
import { TypeSpecimenOutlined } from "@mui/icons-material";
import { useFormikContext } from "formik";
import FileStepper from "./FileStepper";
import LinkableStepper from "./Linkables/LinkableStepper";

const FileSelector = ({}) => {
  const { values } = useFormikContext<ProjectInfo>();
  const type = values.type;
  return type === FileType.Linkable ? <LinkableStepper /> : <FileStepper filetype={type} />;
};
export default FileSelector;
