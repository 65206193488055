import { Checkbox, FormControlLabel } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { UnlockTypes } from "../../../../types/UnlockInfo";

const LockTypeCheckbox = ({ method }) => {
  const { values } = useFormikContext<{ selectedMethods: UnlockTypes[] }>();
  const isDefault = values.selectedMethods.length === 0;
  const selectedMethods = isDefault ? [UnlockTypes.NoLock] : values.selectedMethods || [];
  return (
    <Field
      type="checkbox"
      name="selectedMethods"
      value={method.type}
      key={method.type}
      as={FormControlLabel}
      control={<Checkbox />}
      checked={selectedMethods.includes(method.type)}
      disabled={selectedMethods.length === 2 && !selectedMethods.includes(method.type)}
      label=""
    />
  );
};

export default LockTypeCheckbox;
