import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { AlertType } from "components/Alert";
import { getKy } from "helpers/ky";
import { useAppDispatch } from "store/hooks";
import { setAlert } from "store/slices/alertSlice";

export default function DeleteDialog({ file, loadLinks, open, setOpen }) {
  const dispatch = useAppDispatch();
  const handleDelete = async (shortId) => {
    try {
      const ky = getKy();
      await ky(`/api/v1/submarine`, {
        method: "DELETE",
        json: { shortId },
        timeout: 2147483647,
      });
    } catch (error) {
      dispatch(setAlert({ type: AlertType.Error, message: "Error deleting link" }));
    }
  };

  const deleteLink = async () => {
    if (!file.short_id) {
      throw "No file id";
    }
    await handleDelete(file.short_id);
    loadLinks(0);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Delete submarined link</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete {file?.name}?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="error" onClick={deleteLink}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
