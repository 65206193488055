import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
const InformationCircleIconStyled = ({ ...props }) => {
  return (
    <IconButton>
      <InfoIcon height={"1.5rem"} width={"1.5rem"} {...props} />
    </IconButton>
  );
};
export default InformationCircleIconStyled;
